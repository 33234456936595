import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  FunnelIcon,
  DocumentMagnifyingGlassIcon,
  DocumentTextIcon,
  PencilSquareIcon,
  CalendarDaysIcon,
} from '@heroicons/react/24/outline';
import { useTeam } from '../../contexts/TeamContext';
import { SideBarLayout } from '../organisms/SiderBarLayout';
import { Modal } from '../molecules/Modal';
import { EmptyStates } from '../organisms/EmptyStates';
import { Breadcrumbs } from '../atoms/Breadcrumbs';
import { DividerWithTitleOnLeft } from '../atoms/DividerWithTitleOnLeft';
import { StackedList } from '../atoms/StackedList';
import { Searchbar } from '../atoms/Searchbar';
import { formatDateInWords } from '../../services/utils/momentUtils';
import { renderTextWithLinks } from '../../services/utils/renderTextWithLinks';
import { dynamicApiCall } from '../../services/api/response/callResponsehandler';
import { SingleScreenTitle } from '../atoms/SingleScreenTitle';
import { SingleScreenSubtitle } from '../atoms/SingleScreenSubtitle';
import { SingleScreenMenu } from '../atoms/SingleScreenMenu';
import { NotificationContext } from '../../contexts/NotificationContext';
import { DeleteModalInput } from '../molecules/DeleteModalInput';
import { Accordion } from '../molecules/Accordion';
import { CategoryFilter } from '../molecules/CategoryFilters';
import { SlideOver } from '../molecules/SlideOver';
import { SlideOverFormContainer } from '../molecules/SliderOverFormContainer';
import { SlideOverInputContainer } from '../molecules/SlideOverInputContainer';
import { SlideOverInputLabel } from '../molecules/SlideOverInputLabel';
import { SlideOverInputText } from '../molecules/SlideOverInputText';
import { SlideOverInputTextArea } from '../molecules/SlideOverInputTextArea';
import { SlideOverInputColorPicker } from '../molecules/SlideOverInputColorPicker';
import { SlideOverHandleButtons } from '../molecules/SlideOverHandleButtons';
import { ModalExportContent } from '../organisms/ModalExportContent';
import { mergeClientAndProjectsForExport } from '../../services/utils/mergeClientAndProjectsForExport';

export default function ClientScreen() {
  const navigate = useNavigate();

  /* Access context values using the useTeam hook */
  const { teamUUID } = useTeam();
  /* Access context notifs */
  const { updateOpenNotification, updateNotificationData } =
    useContext(NotificationContext);

  const location = useLocation();
  const { pathname } = location;

  /* Split the pathname by '/' and get the last segment */
  const segments = pathname.split('/');
  const clientName = decodeURIComponent(segments[segments.length - 1]);

  const [client, setClient] = useState({});
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [projects, setProjects] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [openSlideOver, setOpenSlideOver] = useState(false);
  const [modalSource, setModalSource] = useState(null);
  const [searchedProjectsResult, setSearchedProjectsResult] = useState([]);
  const [userPrivileges, setUserPrivileges] = useState('member');
  const [filtersData, setFiltersData] = useState([]);
  const [itemsToExport, setItemsToExport] = useState([]);

  const updateSearchedProject = (searchProjectName) => {
    /* Use the filter method to find clients with names containing the searchClientName */
    const filteredProjects = projects.filter((project) =>
      project.name.toLowerCase().includes(searchProjectName.toLowerCase())
    );

    /* Update the state variable with the filtered clients */
    setSearchedProjectsResult(filteredProjects);
  };

  const updateFromDate = (date) => {
    setFromDate(date);
  };

  const updateToDate = (date) => {
    setToDate(date);
  };

  const updateOpenExportModal = (newOpenExportModal) => {
    setOpenExportModal(newOpenExportModal);
  };

  const updateOpenModal = (newOpenModal) => {
    setOpenModal(newOpenModal);
  };

  const updateOpenSlideOver = (newOpenSlideOver) => {
    setOpenSlideOver(newOpenSlideOver);
  };

  const updateModalSource = (newModalSource) => {
    setModalSource(newModalSource);
  };

  /* BEGINNING CALL API */
  const putTeamClientsCall = async (inputData, reset) => {
    const params = {
      teamUUID,
      uuid: client.uuid,
      name: inputData.clientName || undefined,
      description: inputData.clientDescription || '',
      notes: inputData.clientNotes || '',
      color: inputData.clientColor || undefined,
    };

    await dynamicApiCall({
      callName: 'putTeamClients',
      params: {
        ...params,
        updateOpenSlideOver,
        reset,
        updateOpenNotification,
        updateNotificationData,
        fetchTeamClientsByNamePromise,
        navigate,
      },
    });
  };

  const deleteClientCall = async () => {
    await dynamicApiCall({
      callName: 'deleteTeamClientsByUUID',
      navigate,
      params: {
        teamUUID,
        uuids: client.uuid,
        updateOpenModal,
        updateOpenNotification,
        updateNotificationData,
      },
    });
  };

  const fetchTeamProjectsByTeamClientUUIDPromise = async ({
    teamClientUUID,
    dateStart,
    dateEnd,
  }) => {
    await dynamicApiCall({
      callName: 'getTeamProjectsByTeamClientUUID',
      navigate,
      params: {
        teamUUID,
        teamClientUUID,
        dateStart,
        dateEnd,
        setProjects,
        setSearchedProjectsResult,
      },
    });
  };

  /* Retrieve client informations */
  const fetchTeamClientsByNamePromise = async (clientName) => {
    await dynamicApiCall({
      callName: 'getTeamClients',
      navigate,
      params: { teamUUID, clientName, setClient },
    });
  };

  /* Check if user can access this page */
  const fetchAccessPagePromise = async (teamUUID, page) => {
    await dynamicApiCall({
      callName: 'getAccessPage',
      navigate,
      params: {
        teamUUID,
        navigate,
        page,
        setUserPrivileges,
        updateNotificationData,
        updateOpenNotification,
      },
    });
  };

  /* Retrieve data to export modal */
  const fetchExportDataPromise = async ({
    itemsToExport,
    format,
    isBudgetSelected,
    isAllocationsSelected,
    allocationStartDate,
    allocationEndDate,
  }) => {
    await dynamicApiCall({
      callName: 'getExportData',
      navigate,
      params: {
        itemsToExport,
        teamUUID,
        isBudgetSelected,
        format,
        isAllocationsSelected,
        allocationStartDate,
        allocationEndDate,
        updateOpenNotification,
        updateNotificationData,
      },
    });
  };

  /* END CALL API */

  useEffect(() => {
    if (teamUUID) {
      fetchTeamClientsByNamePromise(clientName);
    }
  }, [clientName, teamUUID]);

  useEffect(() => {
    if (teamUUID) {
      fetchAccessPagePromise(teamUUID, 'Clients');
    }
    if (teamUUID && client.teamUUID && client.teamUUID !== teamUUID) {
      /* Redirect back to portfolio page because this client may not exist in this team */
      navigate('/portfolio');
    }
  }, [teamUUID]);

  useEffect(() => {
    if (teamUUID && client && Object.keys(client).length > 0) {
      const requestData = {
        teamClientUUID: client.uuid,
      };

      if (fromDate && fromDate.trim().length !== 0) {
        requestData.dateStart = fromDate;
      }

      if (toDate && toDate.trim().length !== 0) {
        requestData.dateEnd = toDate;
      }

      fetchTeamProjectsByTeamClientUUIDPromise(requestData);
    }
  }, [fromDate, toDate, teamUUID, client]);

  useEffect(() => {
    if (client) {
      const mergedClient = mergeClientAndProjectsForExport(client, projects);
      setItemsToExport(mergedClient);
    }
  }, [client, projects]);

  return (
    <SideBarLayout>
      {client ? (
        <>
          <div className="px-4 h-full sm:px-6 lg:px-8">
            <div className="lg:flex lg:items-center lg:justify-between">
              <div className="min-w-0 flex-1">
                <Breadcrumbs
                  pages={[
                    {
                      name: `Portfolio`,
                      href: '/portfolio',
                      current: false,
                    },
                    {
                      name: client.name,
                      href: null,
                      current: true,
                    },
                  ]}
                />

                <SingleScreenTitle text={client.name} />
                <SingleScreenSubtitle>
                  <CalendarDaysIcon
                    className="h-5 w-5 flex-shrink-0 mr-1"
                    aria-hidden="true"
                  />
                  <p>
                    Created on{' '}
                    <time dateTime={client.createdAt}>
                      {formatDateInWords(client.createdAt)}
                    </time>
                  </p>
                </SingleScreenSubtitle>

                {client.description && (
                  <SingleScreenSubtitle>
                    <DocumentTextIcon
                      className="h-5 w-5 flex-shrink-0 mr-1"
                      aria-hidden="true"
                    />
                    <p>{client.description}</p>
                  </SingleScreenSubtitle>
                )}

                {client.notes && (
                  <SingleScreenSubtitle>
                    <PencilSquareIcon
                      className="h-5 w-5 flex-shrink-0 mr-1"
                      aria-hidden="true"
                    />
                    <p>{renderTextWithLinks(client.notes)}</p>
                  </SingleScreenSubtitle>
                )}
              </div>

              <SingleScreenMenu
                exportOnClick={() => setOpenExportModal(true)}
                updateModalSource={updateModalSource}
                onClick={() => updateOpenSlideOver(!openSlideOver)}
                updateOpenModal={updateOpenModal}
                modalState={openModal}
                shouldHideButton={userPrivileges === 'member'}
                shouldDisableDeleteButton={projects.length > 0}
              />
            </div>
            <Accordion
              title="Filters"
              className="text-indigo-900 bg-indigo-100 rounded-lg"
              icon={<FunnelIcon className="w-5 h-5 mr-1 text-indigo-900" />}
            >
              <CategoryFilter
                initialFromDate={fromDate}
                initialToDate={toDate}
                filtersData={filtersData}
                setFiltersData={setFiltersData}
                onCalendarDateChange={(from, to) => {
                  updateFromDate(from);
                  updateToDate(to);
                }}
              />
            </Accordion>
            <div className="my-4" />
            <div className="flex items-center">
              <DividerWithTitleOnLeft
                title={`Projects (${searchedProjectsResult.length})`}
              />
              <div className="ml-auto">
                <Searchbar
                  placeholder="Search projects"
                  onChange={updateSearchedProject}
                />
              </div>
            </div>

            <div className="mt-4">
              {searchedProjectsResult.length > 0 ? (
                <StackedList
                  items={searchedProjectsResult}
                  clientName={client.name}
                />
              ) : (
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  No project
                </div>
              )}
            </div>
          </div>
          <Modal open={openModal} setOpen={updateOpenModal}>
            {modalSource === `edit` ? null : (
              <DeleteModalInput
                labelToCopy={client.name}
                onClickCancel={() => updateOpenModal(false)}
                deleteFunction={deleteClientCall}
              />
            )}
          </Modal>

          {/* Export modal */}
          <Modal open={openExportModal} setOpen={updateOpenExportModal}>
            <ModalExportContent
              itemsToExport={itemsToExport}
              fetchExportData={fetchExportDataPromise}
            />
          </Modal>

          {client && Object.keys(client).length > 0 && (
            <SlideOver
              open={openSlideOver}
              setOpen={updateOpenSlideOver}
              title="Edit client"
              defaultValues={{
                clientName: client.name,
                clientDescription: client.description,
                clientNotes: client.notes,
                clientColor: client.color,
              }}
            >
              <SlideOverFormContainer onSubmit={putTeamClientsCall}>
                <SlideOverInputContainer>
                  <SlideOverInputLabel label="Client name" isRequired />
                  <SlideOverInputText name="clientName" isRequired />
                </SlideOverInputContainer>

                <SlideOverInputContainer>
                  <SlideOverInputLabel label="Description" />
                  <SlideOverInputTextArea name="clientDescription" rows={1} />
                </SlideOverInputContainer>

                <SlideOverInputContainer>
                  <SlideOverInputLabel label="Notes" />
                  <SlideOverInputTextArea name="clientNotes" rows={2} />
                </SlideOverInputContainer>

                <SlideOverInputContainer>
                  <SlideOverInputLabel label="Color" />
                  <SlideOverInputColorPicker name="clientColor" />
                </SlideOverInputContainer>

                <SlideOverHandleButtons submitLabel="Save changes" />
              </SlideOverFormContainer>
            </SlideOver>
          )}
        </>
      ) : (
        <div className="flex items-center justify-center h-full">
          <EmptyStates
            displayFor="admin"
            label="Client not found"
            subLabel="This client does not exist in this team."
            buttonLabel="Back to portfolio"
            onClick={() => {
              navigate('/portfolio');
            }}
            iconBack
          >
            <DocumentMagnifyingGlassIcon
              className="mx-auto h-12 w-12 text-gray-400"
              aria-hidden="true"
            />
          </EmptyStates>
        </div>
      )}
    </SideBarLayout>
  );
}
