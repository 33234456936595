import { useLocation, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useState, useRef } from 'react';
import {
  BanknotesIcon,
  DocumentTextIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import { SideBarLayout } from '../organisms/SideBarLayout';
import { useTeam } from '../../contexts/TeamContext';
import { EmptyStates } from '../organisms/EmptyStates';
import { dynamicApiCall } from '../../services/api/response/callResponsehandler';
import { SingleScreenTitle } from '../atoms/SingleScreenTitle';
import { SingleScreenSubtitle } from '../atoms/SingleScreenSubtitle';
import { renderTextWithLinks } from '../../services/utils/renderTextWithLinks';
import { getOverlappingBudgetNames } from '../../services/utils/budgets/getOverlappingBudgetNames';
import { calculateAllocationAmount } from '../../services/utils/budgets/calculateAllocationAmount';
import { calculateTotalAllocationsAmount } from '../../services/utils/budgets/calculateTotalAllocationsAmount';
import { calculateTotalAllocationsAmountForClients } from '../../services/utils/budgets/calculateTotalAllocationsAmountForClients';
import { calculateTotalAllocationsAmountForMembers } from '../../services/utils/budgets/calculateTotalAllocationsAmountForMembers';
import { attachClientsToProjects } from '../../services/utils/budgets/attachClientsToProjects';
import { attachAllocationsToProjects } from '../../services/utils/budgets/attachAllocationsToProjects';
import { SlideOver } from '../molecules/SlideOver';
import { SlideOverFormContainer } from '../molecules/SliderOverFormContainer';
import { SlideOverInputContainer } from '../molecules/SlideOverInputContainer';
import { SlideOverInputLabel } from '../molecules/SlideOverInputLabel';
import { SlideOverInputText } from '../molecules/SlideOverInputText';
import { SlideOverHandleButtons } from '../molecules/SlideOverHandleButtons';
import { SlideOverInputTextArea } from '../molecules/SlideOverInputTextArea';
import { SlideOverInputCurrencyAmount } from '../molecules/SlideOverInputCurrencyAmount';
import { CalendarPicker } from '../atoms/CalendarPicker';
import { Breadcrumbs } from '../atoms/Breadcrumbs';
import { SingleScreenMenu } from '../atoms/SingleScreenMenu';
import { NotificationContext } from '../../contexts/NotificationContext';
import { Modal } from '../molecules/Modal';
import { DeleteModalInput } from '../molecules/DeleteModalInput';
import { AmountConsumptionChart } from '../organisms/AmountConsumptionChart';
import { TableWithStickyHeader } from '../molecules/TableWithStickyHeader';
import {
  tableCellAmountSort,
  tableCellDateSort,
  tableCellTextSort,
} from '../../services/utils/tableFilters';
import { TableCellLink } from '../molecules/TableCellLink';
import { TableCellText } from '../molecules/TableCellText';
import { TableCellToggle } from '../molecules/TableCellToggle';
import { TableCellDate } from '../molecules/TableCellDate';
import { TableCellCurrencyAmount } from '../molecules/TableCellCurrencyAmount';
import { TableCellPulseLoader } from '../molecules/TableCellPulseLoader';
import { TableCellLinksList } from '../molecules/TableCellLinksList';
import { DatePeriodWithDot } from '../molecules/DatePeriodWithDot';
import { SlideOverInputItemsToggle } from '../molecules/SlideOverInputItemsToggle';

export default function BudgetScreen() {
  const navigate = useNavigate();
  const teamData = useTeam();
  const location = useLocation();
  const { updateOpenNotification, updateNotificationData } =
    useContext(NotificationContext);
  const { pathname } = location;
  const segments = pathname.split('/');
  const budgetName = decodeURIComponent(segments[segments.length - 1]);

  const [budget, setBudget] = useState(null);
  const [projects, setProjects] = useState([]);
  const [associatedProjects, setAssociatedProjects] = useState([]);
  const [openSlideOver, setOpenSlideOver] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [userPrivileges, setUserPrivileges] = useState('member');
  const [modalSource, setModalSource] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});
  const [selectedOption, setSelectedOption] = useState('projects'); // State to manage selected dropdown option

  const clientCache = useRef({}); // Use useRef to persist the cache across renders
  const memberCache = useRef({});

  const toggleRow = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const updateOpenSlideOver = (newOpenSlideOver) => {
    setOpenSlideOver(newOpenSlideOver);
  };

  const updateOpenModal = (newOpenModal) => {
    setOpenModal(newOpenModal);
  };

  const updateModalSource = (newModalSource) => {
    setModalSource(newModalSource);
  };

  const putTeamBudgetsCall = async (inputData, reset) => {
    if (!budget) return;

    const params = {
      teamUUID: teamData.teamUUID,
      uuid: budget.uuid,
      name: inputData.budgetName || undefined,
      description: inputData.budgetDescription || '',
      notes: inputData.budgetNotes || '',
      startedAt: inputData.from || undefined,
      endedAt: inputData.to || undefined,
      amount: inputData.budgetAmount || undefined,
      projectsUUIDs:
        Object.entries(inputData.budgetProjects)
          .filter(([key, value]) => value === true)
          .map(([key, value]) => key) || undefined, // Get the keys === true as an array [project1, project2, ...]
      deleteProjectsUUIDs:
        Object.entries(inputData.budgetProjects)
          .filter(([key, value]) => value === false)
          .map(([key, value]) => key) || undefined,
    };

    await dynamicApiCall({
      callName: 'putTeamBudgets',
      params: {
        ...params,
        updateOpenSlideOver,
        reset,
        updateOpenNotification,
        updateNotificationData,
        fetchTeamBudgetsByNamePromise,
        fetchTeamProjectsPromise,
        navigate,
      },
    });
  };

  const deleteBudgetCall = async () => {
    if (!budget) return;

    await dynamicApiCall({
      callName: 'deleteTeamBudgetsByUUID',
      navigate,
      params: {
        teamUUID: teamData.teamUUID,
        uuids: budget.uuid,
        updateOpenModal,
        updateOpenNotification,
        updateNotificationData,
      },
    });
  };

  const fetchTeamBudgetsByNamePromise = async (budgetName) => {
    await dynamicApiCall({
      callName: 'getTeamBudgets',
      navigate,
      params: { teamUUID: teamData.teamUUID, budgetName, setBudget },
    });
  };

  const fetchAccessPagePromise = async (teamUUID, page) => {
    await dynamicApiCall({
      callName: 'getAccessPage',
      navigate,
      params: {
        teamUUID,
        navigate,
        page,
        setUserPrivileges,
        updateNotificationData,
        updateOpenNotification,
      },
    });
  };

  const fetchTeamProjectsPromise = async ({ teamUUID, userUUID }) => {
    await dynamicApiCall({
      callName: 'getTeamProjectsByTeamUUID',
      navigate,
      params: {
        teamUUID,
        userUUID,
        setProjects,
      },
    });
  };

  useEffect(() => {
    if (teamData.teamUUID) {
      fetchAccessPagePromise(teamData.teamUUID, 'Budget');
      fetchTeamProjectsPromise({ teamUUID: teamData.teamUUID });
      fetchTeamBudgetsByNamePromise(budgetName);
    }
  }, [budgetName, teamData.teamUUID]);

  /* Reset expanded row when location change */
  useEffect(() => {
    setExpandedRows({});
  }, [location.pathname]);

  useEffect(() => {
    if (projects.length > 0 && budget) {
      // Filter projects where parsedBudget.uuid matches budget.uuid
      const relevantProjects = projects.filter((project) => {
        const parsedBudgets = JSON.parse(`[${project.budgets}]`);
        return parsedBudgets.some(
          (parsedBudget) =>
            parsedBudget.uuid === budget.uuid &&
            parsedBudget.budgetLinkAssociationDeletedAt === null
        );
      });

      // Attach clients to projects
      attachClientsToProjects(
        relevantProjects,
        teamData.teamUUID,
        clientCache,
        navigate,
        dynamicApiCall
      )
        .then((projectsWithClients) =>
          // Attach allocations to projects
          attachAllocationsToProjects(
            projectsWithClients,
            budget,
            teamData.teamUUID,
            memberCache,
            navigate,
            dynamicApiCall
          )
        )
        .then((updatedProjects) => {
          setAssociatedProjects(updatedProjects);
        });
    }
  }, [projects]);

  return (
    <SideBarLayout>
      {budget ? (
        <>
          <div className="px-4 h-full sm:px-6 lg:px-8">
            <div className="lg:flex lg:items-center lg:justify-between">
              <div className="min-w-0 flex-1">
                <Breadcrumbs
                  pages={[
                    {
                      name: `Budgets`,
                      href: '/budgets',
                      current: false,
                    },
                    {
                      name: budget.name,
                      href: null,
                      current: true,
                    },
                  ]}
                />

                <SingleScreenTitle text={budget.name} />
                <SingleScreenSubtitle>
                  <DatePeriodWithDot
                    from={budget.startedAt}
                    to={budget.endedAt}
                  />
                </SingleScreenSubtitle>

                {budget.description && (
                  <SingleScreenSubtitle>
                    <DocumentTextIcon
                      className="h-5 w-5 flex-shrink-0 mr-1"
                      aria-hidden="true"
                    />
                    <p>{budget.description}</p>
                  </SingleScreenSubtitle>
                )}

                {budget.notes && (
                  <SingleScreenSubtitle>
                    <PencilSquareIcon
                      className="h-5 w-5 flex-shrink-0 mr-1"
                      aria-hidden="true"
                    />
                    <p>{renderTextWithLinks(budget.notes)}</p>
                  </SingleScreenSubtitle>
                )}
              </div>

              <SingleScreenMenu
                updateModalSource={updateModalSource}
                onClick={() => updateOpenSlideOver(!openSlideOver)}
                updateOpenModal={updateOpenModal}
                modalState={openModal}
                shouldHideButton={userPrivileges === 'member'}
                shouldDisableDeleteButton={false}
              />
            </div>

            <div className="mt-6" />

            <AmountConsumptionChart
              label="Amount consumed"
              maxAmount={budget.amount}
              currencySymbol={teamData.teamCurrencySymbol}
              data={
                selectedOption === 'projects'
                  ? associatedProjects.map((project) => ({
                      label: project.name,
                      amount: calculateTotalAllocationsAmount(
                        project,
                        budget,
                        teamData
                      ),
                    }))
                  : selectedOption === 'clients'
                  ? calculateTotalAllocationsAmountForClients(
                      associatedProjects,
                      budget,
                      teamData
                    )
                  : selectedOption === 'members'
                  ? calculateTotalAllocationsAmountForMembers(
                      associatedProjects,
                      budget,
                      teamData
                    )
                  : []
              }
              selectedOption={selectedOption}
              onSelectOption={setSelectedOption}
              dropdownOptions={[
                { value: 'projects', label: 'by projects' },
                { value: 'clients', label: 'by clients' },
                { value: 'members', label: 'by members' },
              ]}
            />

            <div className="mt-4" />

            {associatedProjects.length > 0 ? (
              <TableWithStickyHeader
                tableHeads={{
                  project: {
                    label: 'Project',
                    filteredBy: tableCellTextSort,
                    keyToFilterFrom: 'name',
                  },
                  client: {
                    label: 'Client',
                    filteredBy: tableCellTextSort,
                    keyToFilterFrom: 'client.name',
                  },
                  allocations: {
                    label: 'Allocations',
                    filteredBy: tableCellAmountSort,
                    keyToFilterFrom: 'allocations.length',
                    information:
                      'Only allocations within the budget period appears.',
                  },
                  amount: {
                    label: 'Amount',
                    filteredBy: tableCellAmountSort,
                    keyToFilterFrom: (project) =>
                      calculateTotalAllocationsAmount(
                        project,
                        budget,
                        teamData
                      ),
                  },
                }}
                tableData={associatedProjects}
              >
                {(sortedData) => (
                  <>
                    {sortedData.map((project, index) => (
                      <React.Fragment key={project.uuid || index}>
                        <tr onClick={() => console.log('clicked')}>
                          <TableCellLink
                            data={project}
                            label={project.name}
                            index={index}
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(
                                `/portfolio/${encodeURIComponent(
                                  project.client.name
                                )}/${encodeURIComponent(project.name)}`
                              );
                            }}
                          />

                          <TableCellLink
                            data={project}
                            label={project.client.name}
                            index={index}
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(
                                `/portfolio/${encodeURIComponent(
                                  project.client.name
                                )}`
                              );
                            }}
                          />

                          {project.isAllocationsLoading ? (
                            <TableCellPulseLoader
                              data={project}
                              index={index}
                            />
                          ) : (
                            <TableCellToggle
                              data={project}
                              label={project.allocations.length}
                              index={index}
                              isExpanded={expandedRows[index]}
                              onClick={() =>
                                project.allocations &&
                                project.allocations.length
                                  ? toggleRow(index)
                                  : null
                              }
                            />
                          )}

                          <TableCellCurrencyAmount
                            data={project}
                            label={calculateTotalAllocationsAmount(
                              project,
                              budget,
                              teamData
                            )}
                            index={index}
                            currencySymbol={teamData.teamCurrencySymbol}
                          />
                        </tr>

                        {expandedRows[index] && (
                          <tr key={`expanded-${project.uuid || index}`}>
                            <td
                              colSpan="4" /* Adjust the colspan to match the number of parent array columns */
                              className="px-4 py-2 bg-gray-50 border-x border-y border-indigo-500"
                            >
                              <div className="max-h-96 overflow-y-auto">
                                {/* Adjust the max-h value as needed */}
                                <TableWithStickyHeader
                                  isAToggleTable
                                  title="Allocations details"
                                  tableHeads={{
                                    allocationDate: {
                                      label: 'Date',
                                      filteredBy: tableCellDateSort,
                                      keyToFilterFrom:
                                        'allocation.allocationDate',
                                    },
                                    allocationHours: {
                                      label: 'Hours',
                                      filteredBy: tableCellAmountSort,
                                      keyToFilterFrom:
                                        'allocation.allocationHours',
                                    },

                                    user: {
                                      label: 'Member',
                                      filteredBy: tableCellTextSort,
                                      keyToFilterFrom: 'user.firstname',
                                    },
                                    hourlyRate: {
                                      label: 'Hourly rate',
                                      filteredBy: tableCellAmountSort,
                                      keyToFilterFrom: (allocation) =>
                                        allocation.user?.teamMemberDetails[0]
                                          ?.hourlyRate ||
                                        teamData.teamDefaultHourlyRate,
                                    },
                                    overlappingBudget: {
                                      label: 'Overlapping budget',
                                      information:
                                        'Other budgets associated with this project and valid for the allocation date.',
                                    },
                                    amount: {
                                      label: 'Amount',
                                      information:
                                        'The hourly rate multiplied by the hours allocated. Then divided by the number of budgets.',
                                      filteredBy: tableCellAmountSort,
                                      keyToFilterFrom: (allocation) =>
                                        calculateAllocationAmount(
                                          allocation,
                                          project,
                                          budget,
                                          teamData
                                        ),
                                    },
                                  }}
                                  tableData={project.allocations}
                                >
                                  {(allocationData) => (
                                    <>
                                      {allocationData.map(
                                        (allocation, allocationIndex) => {
                                          const overlappingBudgets =
                                            getOverlappingBudgetNames(
                                              allocation.allocation
                                                .allocationDate,
                                              project.budgets,
                                              budget.uuid
                                            );

                                          return (
                                            <tr
                                              key={
                                                allocation.uuid ||
                                                allocationIndex
                                              }
                                              onClick={() =>
                                                console.log('clicked')
                                              }
                                            >
                                              <TableCellDate
                                                data={allocation}
                                                date={
                                                  allocation.allocation
                                                    .allocationDate
                                                }
                                                index={allocationIndex}
                                              />

                                              <TableCellText
                                                data={allocation}
                                                label={
                                                  allocation.allocation
                                                    .allocationHours
                                                }
                                                index={allocationIndex}
                                              />

                                              <TableCellLink
                                                data={allocation}
                                                label={`${allocation.user.firstname} ${allocation.user.lastname}`}
                                                index={allocationIndex}
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  navigate(`/members`);
                                                }}
                                              />

                                              <TableCellCurrencyAmount
                                                data={allocation}
                                                label={
                                                  allocation.user
                                                    .teamMemberDetails[0]
                                                    ?.hourlyRate ||
                                                  teamData.teamDefaultHourlyRate
                                                }
                                                index={index}
                                                currencySymbol={
                                                  teamData.teamCurrencySymbol
                                                }
                                              />

                                              {overlappingBudgets.length > 0 ? (
                                                <TableCellLinksList
                                                  data={allocation}
                                                  labels={overlappingBudgets}
                                                  index={index}
                                                  onClick={(e, label) => {
                                                    e.stopPropagation(); // Prevents the click event from bubbling up to the parent elements
                                                    navigate(
                                                      `/budgets/${label}`
                                                    );
                                                  }}
                                                />
                                              ) : (
                                                <TableCellText
                                                  data={allocation}
                                                  label="-"
                                                  index={index}
                                                />
                                              )}

                                              <TableCellCurrencyAmount
                                                data={project}
                                                label={calculateAllocationAmount(
                                                  allocation,
                                                  project,
                                                  budget,
                                                  teamData,
                                                  overlappingBudgets.length + 1 // Pass the count directly
                                                )}
                                                index={index}
                                                currencySymbol={
                                                  teamData.teamCurrencySymbol
                                                }
                                              />
                                            </tr>
                                          );
                                        }
                                      )}
                                    </>
                                  )}
                                </TableWithStickyHeader>
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </>
                )}
              </TableWithStickyHeader>
            ) : (
              <p>No associated project</p>
            )}
          </div>

          <Modal open={openModal} setOpen={updateOpenModal}>
            {modalSource === `edit` ? null : (
              <DeleteModalInput
                labelToCopy={budget.name}
                onClickCancel={() => updateOpenModal(false)}
                deleteFunction={deleteBudgetCall}
              />
            )}
          </Modal>

          {budget && Object.keys(budget).length > 0 && (
            <SlideOver
              open={openSlideOver}
              setOpen={updateOpenSlideOver}
              title="Edit budget"
              defaultValues={{
                budgetName: budget.name,
                budgetDescription: budget.description,
                budgetNotes: budget.notes,
                from: budget.startedAt ? budget.startedAt.split('T')[0] : '',
                to: budget.endedAt ? budget.endedAt.split('T')[0] : '',
                budgetAmount: budget.amount,
                budgetProjects: projects.reduce((acc, project) => {
                  // Set to true if project's UUID is in the sortedData, false otherwise
                  acc[project.uuid] = new Set(
                    associatedProjects.map((project) => project.uuid)
                  ).has(project.uuid);
                  return acc;
                }, {}),
              }}
            >
              <SlideOverFormContainer onSubmit={putTeamBudgetsCall}>
                <SlideOverInputContainer>
                  <SlideOverInputLabel label="Budget name" isRequired />
                  <SlideOverInputText name="budgetName" isRequired />
                </SlideOverInputContainer>

                <SlideOverInputContainer>
                  <SlideOverInputLabel label="Validity period" isRequired />
                  <CalendarPicker isRequired name1="from" name2="to" />
                </SlideOverInputContainer>

                <SlideOverInputContainer>
                  <SlideOverInputLabel label="Amount" isRequired />
                  <SlideOverInputCurrencyAmount
                    name="budgetAmount"
                    currencySymbol={teamData.teamCurrencySymbol}
                    currencyName={teamData.teamCurrencyName}
                    isRequired
                  />
                </SlideOverInputContainer>

                <SlideOverInputContainer>
                  <SlideOverInputLabel
                    label="Associated projects"
                    subLabel="Amount will be equally splitted between each selected projects."
                    linkData={{ to: `/portfolio`, label: `Manage projects` }}
                  />
                  <SlideOverInputItemsToggle
                    name="budgetProjects"
                    data={projects.map((project) => ({
                      ...project,
                      dataType: 'projects',
                    }))}
                  />
                </SlideOverInputContainer>

                <SlideOverInputContainer>
                  <SlideOverInputLabel label="Description" />
                  <SlideOverInputTextArea name="budgetDescription" rows={1} />
                </SlideOverInputContainer>

                <SlideOverInputContainer>
                  <SlideOverInputLabel label="Notes" />
                  <SlideOverInputTextArea name="budgetNotes" rows={2} />
                </SlideOverInputContainer>

                <SlideOverHandleButtons submitLabel="Save changes" />
              </SlideOverFormContainer>
            </SlideOver>
          )}
        </>
      ) : (
        <div className="flex items-center justify-center h-full">
          <EmptyStates
            displayFor="admin"
            label="Budget not found"
            subLabel="This budget does not exist in this team."
            buttonLabel="Back to budgets"
            onClick={() => {
              navigate('/budgets');
            }}
            iconBack
          >
            <BanknotesIcon
              className="mx-auto h-12 w-12 text-gray-400"
              aria-hidden="true"
            />
          </EmptyStates>
        </div>
      )}
    </SideBarLayout>
  );
}
