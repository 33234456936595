import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UsersIcon } from '@heroicons/react/24/outline';
import { EmptyStates } from '../organisms/EmptyStates';
import { SideBarLayout } from '../organisms/SideBarLayout';
import { PageHeading } from '../molecules/PageHeading';
import { PageHeadingButton } from '../molecules/PageHeadingButton';
import { useTeam } from '../../contexts/TeamContext';
import { dynamicApiCall } from '../../services/api/response/callResponsehandler';
import { CalendarPicker } from '../atoms/CalendarPicker';
import { NotificationContext } from '../../contexts/NotificationContext';
import { SlideOverFormContainer } from '../molecules/SliderOverFormContainer';
import { SlideOverInputContainer } from '../molecules/SlideOverInputContainer';
import { SlideOverInputLabel } from '../molecules/SlideOverInputLabel';
import { SlideOver } from '../molecules/SlideOver';
import { SlideOverInputText } from '../molecules/SlideOverInputText';
import { SlideOverHandleButtons } from '../molecules/SlideOverHandleButtons';
import { SlideOverInputWorkDays } from '../molecules/SlideOverInputWorkDays';
import { SlideOverInputItemsToggle } from '../molecules/SlideOverInputItemsToggle';
import { SlideOverInputNumberWithTradeBridge } from '../molecules/SlideOverInputNumberWithTradeBridge';
import { BasicCard } from '../atoms/BasicCard';
import { LightAmountConsumptionChart } from '../organisms/LightAmountConsumptionChart';
import { SecondaryButton } from '../atoms/SecondaryButton';
import { TableWithStickyHeader } from '../molecules/TableWithStickyHeader';
import { TableCellText } from '../molecules/TableCellText';
import { TableCellDatesPeriod } from '../molecules/TableCellDatesPeriod';
import { TableCellDropdownMenu } from '../molecules/TableCellDropdownMenu';
import {
  tableCellDateSort,
  tableCellTextSort,
} from '../../services/utils/tableFilters';
import { TableCellTitleSubtitleAndAvatar } from '../molecules/TableCellTitleSubtitleAndAvatar';
import { Badge } from '../atoms/Badge';
import { Modal } from '../molecules/Modal';
import { DeleteModalInput } from '../molecules/DeleteModalInput';
import { SlideOverDropdown } from '../molecules/SlideOverDropdown';

export default function MembersScreen() {
  const navigate = useNavigate();

  /* Access context values using the useTeam hook */
  const {
    teamUUID,
    teamCurrencySymbol,
    teamName,
    teamDefaultDailyRate,
    teamDefaultHourlyRate,
  } = useTeam();

  /* Access context notifs */
  const { updateOpenNotification, updateNotificationData } =
    useContext(NotificationContext);

  const [slideOverDefaultValues, setSlideOverDefaultValues] = useState({
    uuid: null,
    userUUID: null,
    mail: null,
    firstName: '',
    lastName: '',
    access: '',
    contractUUID: '',
    hourlyRate: null,
    dailyRate: null,
    from: '',
    to: '',
    hoursPreferences: null,
    projects: null,
    isChecked: true,
  });

  useEffect(() => {
    if (teamDefaultDailyRate && teamDefaultHourlyRate) {
      setSlideOverDefaultValues({
        ...slideOverDefaultValues,
        dailyRate: teamDefaultDailyRate,
        hourlyRate: teamDefaultHourlyRate,
      });
    }
  }, [teamDefaultDailyRate, teamDefaultHourlyRate]);

  const [isUpdatingMember, setIsUpdatingMember] = useState(false);
  const [people, setPeople] = useState([]);

  const [openMemberSlideOver, setOpenMemberSlideOver] = useState(false);
  const [privileges, setPrivileges] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [teamPlan, setTeamPlan] = useState({});
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const [selectedMemberForModal, setSelectedMemberForModal] = useState(null);
  const [userPrivileges, setUserPrivileges] = useState('member');

  const updateOpenModal = (newOpenModal, member) => {
    if (newOpenModal && member) {
      setSelectedMemberForModal(member);
    } else {
      setSelectedMemberForModal(null);
    }
    setOpenModal(newOpenModal);
  };

  const updateOpenDeleteUserModal = (newOpenModal, member) => {
    if (newOpenModal && member) {
      setSelectedMemberForModal(member);
    } else {
      setSelectedMemberForModal(null);
    }

    setOpenDeleteUserModal(newOpenModal);
  };

  const updateOpenMemberSlideOver = (newOpenSlideOver) => {
    if (newOpenSlideOver === false) {
      setSlideOverDefaultValues({
        uuid: null,
        userUUID: null,
        mail: null,
        firstName: '',
        lastName: '',
        access: '',
        contractUUID: '',
        dailyRate: teamDefaultDailyRate,
        hourlyRate: teamDefaultHourlyRate,
        from: '',
        to: '',
        hoursPreferences: null,
        projects: null,
        isChecked: true,
      });
      setIsUpdatingMember(false);
    }
    setOpenMemberSlideOver(newOpenSlideOver);
  };

  const getSelectedProjects = (projects) => {
    if (!projects) return { selected: [], notSelected: [] };
    const selected = [];
    const notSelected = [];

    Object.keys(projects).forEach((key) => {
      if (projects[key]) {
        selected.push(key);
      } else {
        notSelected.push(key);
      }
    });

    return { selected, notSelected };
  };

  const postTeamMembersCall = async (inputData, reset) => {
    const { selected } = getSelectedProjects(inputData?.projects);

    const params = {
      privilegeUUID: inputData.access !== '' ? inputData.access : undefined,
      contractUUID:
        inputData.contractUUID !== '' ? inputData.contractUUID : undefined,
      hourlyRate: inputData.isChecked ? null : inputData.hourlyRate,
      firstName: inputData.firstName !== '' ? inputData.firstName : undefined,
      lastName: inputData.lastName !== '' ? inputData.lastName : undefined,
      startedAt: inputData.from !== '' ? inputData.from : null,
      endedAt: inputData.to !== '' ? inputData.to : null,
      mail: inputData.mail,
      hoursPreferences: inputData.hoursPreferences,
      teamUUID,
      teamProjectsUUID: selected.length > 0 ? selected : undefined,
    };

    await dynamicApiCall({
      callName: 'postInvitedUsersByMailAndTeamUUID',
      navigate,
      params: {
        ...params,
        reset,
        fetchMembersScreenData,
        updateOpenNotification,
        updateNotificationData,
        updateOpenMemberSlideOver,
      },
    });
  };

  const fetchTeamBillingPlanByTeamUUIDPromise = async (teamUUID) => {
    await dynamicApiCall({
      callName: 'getBillingPlanByTeamUUID',
      navigate,
      params: { teamUUID, setTeamPlan },
    });
  };

  const putTeamMemberCallWithDeactivation = async (inputData) => {
    const params = {
      uuid: inputData.uuid,
      teamUUID,
      deactivatedAt: inputData.deactivatedAt || null,
      endedAt: inputData.endedAt || null,
    };

    await dynamicApiCall({
      callName: 'putTeamMembersByUUID',
      navigate,
      params: {
        ...params,
        updateOpenModal: updateOpenMemberSlideOver,
        fetchInfo: fetchMembersScreenData,
        updateOpenNotification,
        updateNotificationData,
      },
    });
  };

  const putTeamMembersCall = async (inputData, reset) => {
    const teamProjectsUUID = [];
    const deleteTeamProjectsUUID = [];

    Object.entries(inputData.projects).forEach(([uuid, value]) => {
      if (value) {
        teamProjectsUUID.push(uuid);
      } else {
        deleteTeamProjectsUUID.push(uuid);
      }
    });

    const params = {
      uuid: inputData.uuid,
      teamUUID,
      hoursPreferences: inputData.hoursPreferences,
      privilegeUUID: inputData.access,
      contractUUID: inputData.contractUUID || undefined,
      hourlyRate: inputData.isChecked ? null : inputData.hourlyRate,
      startedAt: inputData.from || null,
      endedAt: inputData.to || null,
      deleteTeamProjectsUUID,
      teamProjectsUUID,
    };

    const putTeamMembersByUUIDData = await dynamicApiCall({
      callName: 'putTeamMembersByUUID',
      navigate,
      params: {
        ...params,
        updateOpenModal: updateOpenMemberSlideOver,
        updateOpenNotification,
        updateNotificationData,
      },
    });

    if (
      putTeamMembersByUUIDData &&
      putTeamMembersByUUIDData.type === 'success'
    ) {
      await dynamicApiCall({
        callName: 'putUserByUUID',
        navigate,
        params: {
          uuid: inputData.userUUID,
          firstName:
            inputData.firstName && inputData.firstName !== ''
              ? inputData.firstName
              : undefined,
          lastName:
            inputData.lastName && inputData.lastName !== ''
              ? inputData.lastName
              : undefined,
          mail: inputData.mail,
          fetchInfo: fetchMembersScreenData,
          updateOpenModal: updateOpenMemberSlideOver,
          updateOpenNotification,
          updateNotificationData,
        },
      });
    }
  };

  const handleData = (peopleClicked) => {
    setIsUpdatingMember(true);
    const isChecked = !(peopleClicked.dailyRate && peopleClicked.hourlyRate);

    setSlideOverDefaultValues({
      uuid: peopleClicked.uuid,
      userUUID: peopleClicked.userUUID,
      mail: peopleClicked.mail,
      firstName: peopleClicked.firstName || '',
      lastName: peopleClicked.lastName || '',
      access: peopleClicked.privilegeUUID,
      contractUUID: peopleClicked.contractUUID,
      hourlyRate: peopleClicked.hourlyRate || teamDefaultHourlyRate,
      dailyRate: peopleClicked.dailyRate || teamDefaultDailyRate,
      isChecked,
      from: peopleClicked.startedAt || null,
      to: peopleClicked.endedAt || null,
      hoursPreferences: peopleClicked.hoursPreferences || null,
      projects: peopleClicked.projectsUUID.reduce((acc, project) => {
        acc[project] = true;
        return acc;
      }, {}),
    });

    updateOpenMemberSlideOver(true);
  };

  /* BEGINNING CALL API */
  const fetchTeamMembersByTeamUUIDPromise = async (teamUUID) => {
    setIsLoading(true);
    await dynamicApiCall({
      callName: 'getTeamMembersByTeamUUID',
      navigate,
      params: {
        teamUUID,
        setPeople,
      },
    });
    setIsLoading(false);
  };

  const fetchContractsByAuthTokenPromise = async () => {
    await dynamicApiCall({
      callName: 'getContractsByAuthToken',
      navigate,
      params: { setContracts },
    });
  };

  const fetchTeamProjectsByTeamUUIDPromise = async () => {
    await dynamicApiCall({
      callName: 'getTeamProjectsByTeamUUID',
      navigate,
      params: { teamUUID, setProjects },
    });
  };

  /* Get all existing privileges */
  const fetchPrivilegesData = async () => {
    await dynamicApiCall({
      callName: 'getPrivileges',
      navigate,
      params: { names: 'member,admin,owner', setPrivileges },
    });
  };

  const fetchMembersScreenData = async () => {
    await fetchTeamMembersByTeamUUIDPromise(teamUUID);
    await fetchContractsByAuthTokenPromise();
    await fetchTeamProjectsByTeamUUIDPromise(teamUUID);
    await fetchPrivilegesData();
    await fetchTeamBillingPlanByTeamUUIDPromise(teamUUID);
  };

  const deleteTeamMembersByUUIDPromise = async (member) => {
    await dynamicApiCall({
      callName: 'deleteTeamMembersByUUID',
      navigate,
      params: {
        uuid: member.uuid,
        teamUUID: member.teamUUID,
        fetchMembersScreenData,
        updateOpenNotification,
        updateNotificationData,
      },
    });
  };

  /* Check if user can access this page */
  const fetchAccessPagePromise = async (teamUUID, page) => {
    await dynamicApiCall({
      callName: 'getAccessPage',
      navigate,
      params: {
        teamUUID,
        navigate,
        page,
        setUserPrivileges,
        updateNotificationData,
        updateOpenNotification,
      },
    });
  };

  /* Call to get teams infos */
  const fetchTeamsByUUIDPromise = async () => {
    await dynamicApiCall({
      callName: 'getTeamsByUUID',
      navigate,
      params: {
        teamUUID,
      },
    });
  };
  /* END CALL API */

  const getDropdownItems = (member) => {
    if (member.deactivatedAt) {
      return [
        {
          label: 'Reactivate user',
          onClick: () => {
            putTeamMemberCallWithDeactivation({
              ...member,
              deactivatedAt: null,
            });
          },
          hasSeparator: true,
        },
        {
          label: 'Delete user',
          onClick: () => updateOpenDeleteUserModal(true, member),
          textColor: 'text-red-600',
        },
      ];
    }

    const items = [
      {
        label: 'Edit',
        onClick: () => {
          handleData(member);
        },
        hasSeparator: true,
      },
    ];

    if (member.access === 'owner') {
      items.push({
        label: 'Switch ownership',
        onClick: () =>
          navigate(`/settings/teams/${encodeURIComponent(teamName)}/general`),
        textColor: 'text-red-600',
      });
    } else {
      items.push({
        label: 'Remove access',
        onClick: () => updateOpenModal(true, member),
        textColor: 'text-red-600',
      });
    }

    return items;
  };

  useEffect(() => {
    if (teamUUID) {
      fetchAccessPagePromise(teamUUID, 'Members');
      fetchMembersScreenData();
      fetchTeamsByUUIDPromise();
    }
  }, [teamUUID, navigate]);

  return (
    <SideBarLayout>
      <div className="px-4 h-full sm:px-6 lg:px-8">
        <BasicCard>
          <div className="flex justify-between mb-1">
            <p className="text-primary">Enterprise</p>
            <SecondaryButton
              id="Edit subscription"
              label="Edit subscription"
              onClick={() =>
                navigate(
                  `/settings/teams/${encodeURIComponent(
                    teamName
                  )}/plans&billing`
                )
              }
              isDisabled={userPrivileges !== 'owner'}
              className={`  focus-visible:outline-indigo-600 ${
                userPrivileges !== 'owner'
                  ? 'cursor-not-allowed text-white bg-gray-300'
                  : 'cursor-pointer text-indigo-700 border border-indigo-700 hover:bg-indigo-200'
              }`}
            />
          </div>

          <div className="mt-6" />
          <LightAmountConsumptionChart
            min={0}
            max={teamPlan.billingPlanSeats}
            value={people.length}
            label="Seats usage :"
          />
        </BasicCard>

        <div className="mt-4" />

        <BasicCard>
          <PageHeading
            heading={`Team members (${people.length})`}
            subheading={
              <p className="text-xs font-light text-gray-500">
                <a
                  href={`${process.env.REACT_APP_LANDING_URL}/privileges`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-indigo-600 hover:text-indigo-500"
                >
                  Learn more{' '}
                </a>
                about roles and users.
              </p>
            }
          >
            <PageHeadingButton
              label="Add member"
              isDisabled={
                userPrivileges !== 'owner' && userPrivileges !== 'admin'
              }
              onClick={() => setOpenMemberSlideOver(true)}
              className={`focus-visible:outline-indigo-600 ${
                userPrivileges !== 'owner' && userPrivileges !== 'admin'
                  ? 'cursor-not-allowed text-white bg-gray-300'
                  : 'cursor-pointer text-white bg-indigo-600 hover:bg-indigo-500'
              }`}
            />
          </PageHeading>

          {isLoading ? (
            <div className="mt-6">
              {[...Array(3)].map((_, idx) => (
                <div
                  key={idx}
                  className="animate-pulse flex space-x-4 mb-4 border-b pb-4"
                >
                  <div className="rounded-full bg-gray-300 h-12 w-12" />
                  <div className="flex-1 space-y-4 py-1">
                    <div className="h-4 bg-gray-300 rounded w-3/4" />
                    <div className="h-4 bg-gray-300 rounded w-1/2" />
                  </div>
                </div>
              ))}
            </div>
          ) : !people.length ? (
            !isLoading && (
              <EmptyStates
                displayFor={userPrivileges}
                label="No members"
                subLabel="Get started by adding a new member."
                buttonLabel="Add member"
                onClick={() => setOpenMemberSlideOver(true)}
              >
                <UsersIcon
                  className="mx-auto h-12 w-12 text-gray-400"
                  aria-hidden="true"
                />
              </EmptyStates>
            )
          ) : (
            <TableWithStickyHeader
              tableHeads={{
                name: {
                  label: 'Name',
                  filteredBy: tableCellTextSort,
                  keyToFilterFrom: 'firstNAme',
                },
                role: {
                  label: 'Role',
                  filteredBy: tableCellTextSort,
                  keyToFilterFrom: 'access',
                },
                contractType: {
                  label: 'Contract type',
                  filteredBy: tableCellTextSort,
                  keyToFilterFrom: 'contract',
                },
                contractValidity: {
                  label: 'Contract validity',
                  filteredBy: tableCellDateSort,
                  keyToFilterFrom: 'startedAt',
                },
                ...(userPrivileges === 'owner' || userPrivileges === 'admin'
                  ? { actionLabel: { label: 'Actions' } }
                  : {}),
              }}
              tableData={people}
            >
              {(sortedData) => (
                <>
                  {sortedData.map((member, index) => (
                    <tr
                      key={index}
                      className={`${
                        member.deactivatedAt
                          ? 'cursor-not-allowed'
                          : 'cursor-pointer hover:bg-slate-100'
                      }`}
                      onClick={() => {
                        if (!member.deactivatedAt) {
                          handleData(member);
                        }
                      }}
                    >
                      <TableCellTitleSubtitleAndAvatar
                        data={member}
                        label={`${
                          member.firstName
                            ? member.firstName[0].toUpperCase() +
                              member.firstName.slice(1)
                            : ''
                        } ${
                          member.lastName ? member.lastName.toUpperCase() : ''
                        }`.trim()}
                        sublabel={member.mail || 'No email provided'}
                        badge={
                          member.deactivatedAt ? (
                            <Badge label="Deactivated" color="gray" />
                          ) : null
                        }
                        index={index}
                      />

                      <TableCellText
                        data={member}
                        label={
                          member.deactivatedAt
                            ? 'No access'
                            : member.access[0].toUpperCase() +
                              member.access.slice(1)
                        }
                        index={index}
                      />

                      <TableCellText
                        data={member}
                        label={member.contract || '-'}
                        index={index}
                      />

                      <TableCellDatesPeriod
                        data={member}
                        from={member.startedAt}
                        to={member.endedAt}
                        index={index}
                      />

                      {(userPrivileges === 'owner' ||
                        userPrivileges === 'admin') && (
                        <TableCellDropdownMenu
                          data={member}
                          index={index}
                          dropdownItems={getDropdownItems(member)}
                        />
                      )}
                    </tr>
                  ))}
                </>
              )}
            </TableWithStickyHeader>
          )}
        </BasicCard>
      </div>

      {/* Modal */}
      <Modal open={openModal} setOpen={updateOpenModal}>
        <p className="text-primary">Remove access</p>
        <div className="mt-4" />
        <p className="text-sm text-gray-500">
          Once removed,{' '}
          <span className="font-medium">
            {selectedMemberForModal?.firstName ||
            selectedMemberForModal?.lastName
              ? `${selectedMemberForModal?.firstName || ''} ${
                  selectedMemberForModal?.lastName || ''
                }`.trim()
              : selectedMemberForModal?.mail}
          </span>{' '}
          will no longer be able to access your workspace.
        </p>

        <ul className="list-disc pl-5 mt-4 ml-2 text-sm text-gray-500">
          <li className="mb-2">
            If deactivated, their account will still exist and appear in
            exports, but it will be unselectable for project associations, and
            the contract validity will be set to today.
          </li>
          <li>
            If deleted, their account and data will be permanently removed.
          </li>
        </ul>

        <p className="mt-4 rounded bg-indigo-100 px-3 py-3 text-sm text-gray-500">
          Only deletion will affect the number of seats on your account. To add
          more seats, please update your{' '}
          <button
            type="button"
            onClick={() =>
              navigate(
                `/settings/teams/${encodeURIComponent(teamName)}/plans&billing`
              )
            }
            className="text-indigo-600 bg-transparent border-0 cursor-pointer hover:underline"
          >
            subscription plan
          </button>
          .
        </p>

        <div className="mt-4 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            onClick={() => {
              setOpenModal(false);
              updateOpenDeleteUserModal(true, selectedMemberForModal);
            }}
            className="flex justify-center rounded-md bg-red-600 hover:bg-red-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Delete user
          </button>
          <button
            type="button"
            className="mt-3 mr-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            onClick={() => {
              putTeamMemberCallWithDeactivation({
                ...selectedMemberForModal,
                deactivatedAt: new Date().toISOString(),
                endedAt: new Date().toISOString(),
              });
              setOpenModal(false);
            }}
          >
            Deactivate user
          </button>
        </div>
      </Modal>

      {/* Delete user modal */}
      <Modal open={openDeleteUserModal} setOpen={updateOpenDeleteUserModal}>
        <DeleteModalInput
          labelToCopy={selectedMemberForModal?.mail}
          onClickCancel={() => updateOpenDeleteUserModal(false)}
          deleteFunction={() => {
            deleteTeamMembersByUUIDPromise(selectedMemberForModal);
            setOpenDeleteUserModal(false);
          }}
        />
      </Modal>

      {/* SlideOverMenu */}
      <SlideOver
        open={openMemberSlideOver}
        setOpen={updateOpenMemberSlideOver}
        title="Add member"
        defaultValues={slideOverDefaultValues}
      >
        <SlideOverFormContainer
          onSubmit={isUpdatingMember ? putTeamMembersCall : postTeamMembersCall}
        >
          <SlideOverInputContainer>
            <SlideOverInputLabel label="Email" isRequired />
            <SlideOverInputText name="mail" type="email" isRequired />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel label="Firstname" />
            <SlideOverInputText name="firstName" />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel label="Lastname" />
            <SlideOverInputText name="lastName" />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel label="Contract type" />
            <SlideOverDropdown
              name="contractUUID"
              data={contracts}
              showDescription
              placeholder="Select a contract type"
            />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel label="Availability" />
            <CalendarPicker />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel
              label="Payment rate"
              linkData={{
                to: `/settings/teams/${encodeURIComponent(
                  teamName
                )}/hours&currency`,
                label: `Manage team settings`,
              }}
            />
            <SlideOverInputNumberWithTradeBridge
              buttonLabel="Save"
              inputType="number"
              inputPrefix={teamCurrencySymbol}
              inputSuffix="/ day"
              inputPrefixTraded={teamCurrencySymbol}
              inputSuffixTraded="/ hour"
              name="hourlyRate"
              nameToWatch="hoursPreferences"
              nameToWatch2="dailyRate"
              isUpdatingUser={!!isUpdatingMember}
            />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel
              label="Hours preferences"
              linkData={{
                to: `/settings/teams/${encodeURIComponent(
                  teamName
                )}/hours&currency`,
                label: `Manage team settings`,
              }}
            />
            <SlideOverInputWorkDays name="hoursPreferences" />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel label="Role" />
            <SlideOverDropdown
              name="access"
              data={privileges}
              showDescription
            />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel
              label="Projects"
              linkData={{
                to: `/portfolio`,
                label: `Manage projects`,
              }}
            />
            <SlideOverInputItemsToggle name="projects" data={projects} />
          </SlideOverInputContainer>

          <SlideOverHandleButtons
            submitLabel={isUpdatingMember ? 'Save changes' : 'Send invite'}
          />
        </SlideOverFormContainer>
      </SlideOver>
    </SideBarLayout>
  );
}
