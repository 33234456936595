import { useState, useRef, useEffect } from 'react';
import {
  ChevronDownIcon,
  CheckIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import { useSlideOverFormContext } from './SlideOver';
import { InputErrorMessage } from '../atoms/InputErrorMessage';

export const SlideOverDropdown = ({
  name,
  placeholder,
  data,
  isRequired,
  showColorDot = false,
  showDescription = false,
  customFunction = null,
}) => {
  const { register, errors, setValue, watch } = useSlideOverFormContext();

  const preselectedItem = data.find((item) => item.uuid === watch(name));
  const [query, setQuery] = useState(preselectedItem?.name || '');
  const [filteredData, setFilteredData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(preselectedItem || null);
  const [isEditing, setIsEditing] = useState(false);
  const dropdownRef = useRef(null);
  const selectedItemRef = useRef(null);

  useEffect(() => {
    if (!preselectedItem) {
      setQuery('');
      setSelectedItem(null);
    } else {
      setQuery(preselectedItem.name);
      setSelectedItem(preselectedItem);
    }
  }, [preselectedItem, name, setValue]);

  // Filter and sort data by name (A → Z)
  useEffect(() => {
    const sortedData = [...data].sort((a, b) => a.name.localeCompare(b.name));
    setFilteredData(
      query === ''
        ? sortedData
        : sortedData.filter((item) =>
            item.name.toLowerCase().includes(query.toLowerCase())
          )
    );
  }, [query, data]);

  // Handle click outside: reset or select first item
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        if (query === '' && selectedItem) {
          setQuery(selectedItem.name);
        } else if (filteredData.length > 0) {
          handleSelect(filteredData[0]);
        } else {
          setQuery('');
          setSelectedItem(null);
        }
        setIsOpen(false);
        setIsEditing(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen, filteredData, selectedItem, query]);

  const handleSelect = (item) => {
    if (customFunction) {
      customFunction(item);
    }

    setSelectedItem(item);
    setQuery(item.name);
    setValue(name, item.uuid, { shouldDirty: true });
    setIsOpen(false);
    setIsEditing(false);
  };

  const handleInputClick = () => {
    if (!isOpen) {
      setIsOpen(true);
      setIsEditing(true);
    }
    setQuery('');
  };

  // 🔄 Reset selected item
  const handleResetSelection = () => {
    setSelectedItem(null);
    setQuery('');
    setValue(name, null, { shouldDirty: true });
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen && selectedItemRef.current) {
      selectedItemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [isOpen, filteredData]);

  return (
    <div className="relative w-full sm:col-span-2" ref={dropdownRef}>
      {/* Hidden Input for UUID */}
      <input
        type="hidden"
        name={name}
        value={selectedItem?.uuid || ''}
        {...register(name, {
          required: isRequired ? 'This input is required.' : false,
        })}
      />

      <div className="relative">
        {preselectedItem?.name === `owner` ? (
          <input
            className="bg-gray-100 cursor-not-allowed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            type="text"
            placeholder={preselectedItem?.name || placeholder}
            disabled
          />
        ) : (
          <>
            <input
              type="text"
              name={`${name}_display`}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onClick={handleInputClick}
              placeholder={
                isEditing && selectedItem ? selectedItem.name : placeholder
              }
              autoComplete="off"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />

            {/* ❌ X-Mark Icon for Reset */}
            {selectedItem && (
              <button
                type="button"
                onClick={handleResetSelection}
                className="absolute inset-y-0 right-8 flex items-center pr-2 text-gray-400 hover:text-red-500 focus:outline-none"
              >
                <XMarkIcon className="h-4 w-4" aria-hidden="true" />
              </button>
            )}

            {/* Chevron Down Icon */}
            <div className="absolute inset-y-0 right-2 flex items-center pointer-events-none">
              <ChevronDownIcon
                className={`h-4 w-4 text-gray-500 transform transition-transform ${
                  isOpen ? 'rotate-180' : 'rotate-0'
                }`}
              />
            </div>
          </>
        )}
      </div>

      {isOpen && (
        <ul className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {filteredData.length === 0 ? (
            <li className="cursor-default select-none p-2 text-gray-500">
              No results found.
            </li>
          ) : (
            filteredData.map((item) => (
              <li
                key={item.uuid}
                className="p-1"
                ref={selectedItem?.uuid === item.uuid ? selectedItemRef : null}
              >
                <button
                  type="button"
                  onClick={() => handleSelect(item)}
                  className="group w-full flex items-center justify-between text-left cursor-pointer select-none p-2 hover:bg-indigo-500 hover:text-white focus:bg-indigo-500 focus:text-white rounded-md"
                >
                  <div className="flex items-center">
                    {showColorDot && (
                      <span
                        className={`inline-block h-3 w-3 flex-shrink-0 rounded-full mr-2 ${
                          item.color
                            ? item.color
                            : 'bg-white border-1 border-gray-300'
                        }`}
                      />
                    )}
                    <span
                      className={`break-all ${
                        selectedItem?.uuid === item.uuid ? 'font-medium' : ''
                      }`}
                    >
                      {item.name}
                    </span>
                  </div>
                  {selectedItem?.uuid === item.uuid && (
                    <CheckIcon
                      className="h-5 w-5 text-indigo-600 group-hover:text-white group-focus:text-white transition-colors"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </li>
            ))
          )}
        </ul>
      )}

      <InputErrorMessage errors={errors} name={name} />
    </div>
  );
};
