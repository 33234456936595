import { Fragment, useState, useEffect, useContext, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import {
  Bars3Icon,
  FolderIcon,
  UsersIcon,
  PencilIcon,
  Cog6ToothIcon,
  BanknotesIcon,
  ArrowsRightLeftIcon,
  ChevronRightIcon,
  ComputerDesktopIcon,
} from '@heroicons/react/24/outline';
import { CheckIcon, PlusIcon } from '@heroicons/react/20/solid';
import { Link, useNavigate } from 'react-router-dom';
import { dynamicApiCall } from '../../services/api/response/callResponsehandler';
import { useTeam } from '../../contexts/TeamContext';
import { AvatarWithPlaceholder } from '../atoms/AvatarWithPlaceholder';
import { Modal } from '../molecules/Modal';
import { AddMemberPopupHeader } from '../molecules/AddMemberPopupHeader';
import { ModalHandleButtons } from '../molecules/ModalHandleButtons';
import { TabsWithUnderline } from '../molecules/TabsWithUnderline';
import { LabelInput } from '../atoms/LabelInput';
import { NotificationContext } from '../../contexts/NotificationContext';
import LogoWithTextSvg from '../atoms/LogoWithTextSvg';
import { productTour } from '../../services/utils/productTour';
import DropdownButtonsMenu from '../atoms/DropdownButtonsMenu';
import { useUser } from '../../contexts/UserContext';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/** 1) Factor out sidebar content so it can be reused for both mobile & desktop * */
function SidebarContent({
  dropdownRef,
  isDropdownOpen,
  toggleDropdown,
  handleKeyDown,
  team,
  teams,
  userData,
  handleTeamClick,
  setIsDropdownOpen,
  navigation,
  teamName,
  setShouldRestartGuidedTour,
  updateOpenModal,
  openModal,
  navigate,
  // plus any other props or states you need inside the sidebar
}) {
  const selectedTeam = team || null;

  const teamPlaceholder = selectedTeam ? selectedTeam.initial : '-';
  const teamLabel = selectedTeam ? selectedTeam.name : 'No workspace';
  const userRole = userData?.privilegeName
    ? userData.privilegeName.charAt(0).toUpperCase() +
      userData.privilegeName.slice(1)
    : '';

  return (
    <div className="flex grow flex-col gap-y-5 bg-gray-900 px-6">
      {/* Top Team selector dropdown */}
      <div className="relative mt-1 -mx-2" ref={dropdownRef}>
        <button
          id="menuYourTeams"
          type="button"
          onClick={toggleDropdown}
          onKeyDown={handleKeyDown}
          role="button"
          tabIndex={0}
          className="p-1 group flex items-center justify-between w-full gap-x-3 rounded-md text-sm font-semibold leading-6 text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
        >
          <div className="flex items-center flex-grow overflow-hidden">
            <div className="-ml-2">
              <AvatarWithPlaceholder
                className="bg-gray-800 flex-shrink-0 mr-2"
                placeholder={teamPlaceholder}
                size="md"
                rounded="lg"
              />
            </div>
            <div className="flex flex-col max-w-full overflow-hidden">
              <span className="truncate text-white font-semibold">
                {teamLabel}
              </span>
              {/* Show the user’s privilege only if we do have a selected team */}
              {selectedTeam && userRole && (
                <span className="text-left truncate text-gray-400 font-light">
                  {userRole}
                </span>
              )}
            </div>
          </div>
          <ArrowsRightLeftIcon
            className="h-5 w-5 text-gray-400 group-hover:text-white group-focus:text-white flex-shrink-0"
            aria-hidden="true"
          />
        </button>

        {isDropdownOpen && (
          <ul
            role="list"
            className="absolute left-full top-0 ml-2 bg-gray-800 shadow-lg rounded-md px-1"
          >
            <div className="text-xs font-semibold ml-2 my-1 leading-6 text-gray-400">
              Your workspaces
            </div>

            {/* If teams is empty, show "No workspace" */}
            {teams.length === 0 ? (
              <li className="text-gray-400 font-light text-sm p-2">
                No workspace
              </li>
            ) : (
              teams.map((team) => {
                const privilege = team.privilegeName || 'member';
                return (
                  <li key={team.uuid}>
                    <button
                      type="button"
                      onClick={(e) => {
                        handleTeamClick(team, e);
                        setIsDropdownOpen(false);
                      }}
                      className={classNames(
                        team.isSelected
                          ? 'bg-gray-700 text-white'
                          : 'text-gray-400 hover:text-white hover:bg-gray-700',
                        'group flex items-center w-full mb-1 rounded-md p-2 text-sm leading-6 font-semibold'
                      )}
                    >
                      <AvatarWithPlaceholder
                        className="bg-gray-800 flex-shrink-0"
                        placeholder={team.initial}
                        size="sm"
                        rounded="lg"
                        margin="mr-2"
                      />
                      <div className="flex flex-col ml-2 truncate">
                        <span>{team.name}</span>
                        <span className="text-left truncate text-gray-400 font-light">
                          {privilege.charAt(0).toUpperCase() +
                            privilege.slice(1)}
                        </span>
                      </div>
                      {team.isSelected && (
                        <CheckIcon className="h-5 w-5 ml-2 text-white" />
                      )}
                    </button>
                  </li>
                );
              })
            )}

            <div className="border-t border-gray-500 mx-2" />

            <button
              type="button"
              onClick={() =>
                navigate('/team/create', {
                  state: {
                    isFirstTeam: false,
                  },
                })
              }
              className="text-gray-400 hover:text-white my-1 hover:bg-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full items-center"
            >
              <PlusIcon
                className="-mr-1 h-5 w-5 text-gray-400 group-hover:text-white"
                aria-hidden="true"
              />
              <span className="truncate">Create new workspace</span>
            </button>
          </ul>
        )}
      </div>

      {/* Nav links */}
      <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="-mx-2 space-y-1">
              {navigation.map((item) => (
                <li key={item.name} id={`menu${item.name}`}>
                  <Link
                    id={`menuLink${item.name}`}
                    to={item.href}
                    className={classNames(
                      item.current
                        ? 'bg-gray-700 text-white'
                        : 'text-gray-400 hover:text-white hover:bg-gray-700',
                      'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? 'text-white'
                          : 'text-gray-400 group-hover:text-white',
                        'h-6 w-6 shrink-0'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </li>

          <li className="mt-auto -mx-2 space-y-1">
            <div className="border-t border-gray-500 -mx-4" />

            {/* Product Tour Button */}
            <button
              type="button"
              onClick={() => {
                localStorage.removeItem('guideStarted');
                setShouldRestartGuidedTour(true);
              }}
              className="text-gray-400 hover:text-white hover:bg-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full items-center"
            >
              <ComputerDesktopIcon
                className="-mr-1 h-5 w-5 text-gray-400 group-hover:text-white"
                aria-hidden="true"
              />
              <span className="truncate">Product Tour</span>
            </button>

            {/* Settings Dropdown */}
            <div className="relative group">
              <button
                type="button"
                className="text-gray-400 hover:text-white hover:bg-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full items-center justify-between"
              >
                <div className="flex items-center gap-x-3">
                  <Cog6ToothIcon
                    className="-mr-1 h-5 w-5 text-gray-400 group-hover:text-white"
                    aria-hidden="true"
                  />
                  <span className="truncate">Settings</span>
                </div>

                <ChevronRightIcon
                  className="h-5 w-5 text-gray-400 group-hover:text-white"
                  aria-hidden="true"
                />
              </button>

              <DropdownButtonsMenu
                items={[
                  {
                    name: 'Team settings',
                    onClick: () =>
                      navigate(
                        `/settings/teams/${encodeURIComponent(
                          teamName
                        )}/plans&billing`
                      ),
                  },
                  {
                    name: 'Personal settings',
                    onClick: () => updateOpenModal(!openModal),
                  },
                  {
                    name: 'Log out',
                    onClick: () => {
                      localStorage.removeItem('authToken');
                      navigate('/login');
                    },
                  },
                ]}
              />
            </div>
          </li>
        </ul>
      </nav>

      <div className="flex w-full -ml-2 mb-4">
        <LogoWithTextSvg className="w-auto h-10 object-contain opacity-90" />
      </div>
    </div>
  );
}

/** 2) Main SideBarLayout component * */
export const SideBarLayout = ({ children }) => {
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // Existing states and logic
  const [openModal, setOpenModal] = useState(false);
  const [modalTabs, setModalTabs] = useState([
    { name: 'Reset password', current: true },
  ]);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [teams, setTeams] = useState([]);
  const [shouldRestartGuidedTour, setShouldRestartGuidedTour] = useState(false);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const {
    updateTeamUUID,
    teamUUID,
    team,
    teamName,
    updateTeam,
    updateTeamName,
    updateTeamCurrencyUUID,
    updateTeamCurrencySymbol,
    updateTeamCurrencyName,
    updateTeamDefaultHoursPreferences,
    updateTeamDefaultHourlyRate,
    updateTeamDefaultDailyRate,
  } = useTeam();

  const { userData, setUserData } = useUser();

  const { updateOpenNotification, updateNotificationData } =
    useContext(NotificationContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const toggleDropdown = () => setIsDropdownOpen((prev) => !prev);
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      toggleDropdown();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const updateModalTabs = (name) => {
    const updatedTabs = modalTabs.map((tab) => ({
      ...tab,
      current: tab.name === name,
    }));
    setModalTabs(updatedTabs);
  };

  const updateOpenModal = (newOpenModal) => {
    setOpenModal(newOpenModal);
  };

  useEffect(() => {
    // Drive the product tour if needed
    const guideAlreadyStarted = localStorage.getItem('guideStarted') === 'true';
    const authToken = localStorage.getItem('authToken');
    if ((!guideAlreadyStarted || shouldRestartGuidedTour) && authToken) {
      productTour.drive();
      localStorage.setItem('guideStarted', 'true');
      setShouldRestartGuidedTour(false);
    }
  }, [shouldRestartGuidedTour]);

  const navigation = [
    {
      name: 'Allocations',
      href: '/allocations',
      icon: PencilIcon,
      current: window.location.pathname.includes('/allocations'),
    },
    {
      name: 'Portfolio',
      href: '/portfolio',
      icon: FolderIcon,
      current: window.location.pathname.includes('/portfolio'),
    },
    {
      name: 'Budgets',
      href: '/budgets',
      icon: BanknotesIcon,
      current: window.location.pathname.includes('/budgets'),
    },
    {
      name: 'Members',
      href: '/members',
      icon: UsersIcon,
      current: window.location.pathname.includes('/members'),
    },
  ];

  // --- API calls (unchanged) ---
  const fetchPutUserByUUIDData = async () => {
    if (
      userData.newPassword &&
      userData.newPasswordConfirm &&
      userData.newPassword !== userData.newPasswordConfirm
    ) {
      setPasswordsMatch(false);
      return;
    }

    await dynamicApiCall({
      callName: 'putUserByUUID',
      navigate,
      params: {
        firstName: userData.firstName,
        lastName: userData.lastName,
        mail: userData.mail,
        currentPassword: userData.currentPassword || undefined,
        newPassword: userData.newPassword || undefined,
        updateOpenModal,
        updateOpenNotification,
        updateNotificationData,
      },
    });
  };

  const fetchTeamMembersByUsersUUIDPromise = async () => {
    const getTeamMembersByAuthTokenData = await dynamicApiCall({
      callName: 'getTeamMembersByAuthToken',
    });
    if (getTeamMembersByAuthTokenData) {
      const teamUUIDFromParams = localStorage.getItem('tmx') || teamUUID;
      getTeamMembersByAuthTokenData.forEach((team, index) => {
        team.initial = team.name.charAt(0).toUpperCase();
        if (index === 0 && teamUUIDFromParams === null) {
          team.isSelected = true;
          updateTeamUUID(team.uuid);
          updateTeamName(team.name);
          updateTeamCurrencyUUID(team.currencyUUID);
          updateTeamCurrencySymbol(team.currencySymbol);
          updateTeamCurrencyName(team.currencyName);
          updateTeamDefaultHoursPreferences(team.defaultHoursPreferences);
          updateTeamDefaultHourlyRate(team.defaultHourlyRate);
          updateTeamDefaultDailyRate(team.defaultDailyRate);
        } else if (teamUUIDFromParams === team.uuid) {
          team.isSelected = true;
          updateTeamUUID(team.uuid);
          updateTeamName(team.name);
          updateTeamCurrencyUUID(team.currencyUUID);
          updateTeamCurrencySymbol(team.currencySymbol);
          updateTeamCurrencyName(team.currencyName);
          updateTeamDefaultHoursPreferences(team.defaultHoursPreferences);
          updateTeamDefaultHourlyRate(team.defaultHourlyRate);
          updateTeamDefaultDailyRate(team.defaultDailyRate);
        }
      });
      const selectedTeam =
        getTeamMembersByAuthTokenData.find((team) => team.isSelected) || null;

      updateTeam(selectedTeam);
      setTeams([...getTeamMembersByAuthTokenData]);
    }
  };

  const fetchAuthTokenValidityPromise = async (authToken) => {
    await dynamicApiCall({
      callName: 'getAuthTokenValidity',
      navigate,
      params: {
        authToken,
      },
    });
  };

  const getUserInfoAndTeam = async () => {
    await fetchTeamMembersByUsersUUIDPromise();
  };

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      fetchAuthTokenValidityPromise(authToken);
    } else if (authToken === null) {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    getUserInfoAndTeam();
  }, [teamName]);

  useEffect(() => {
    setPasswordsMatch(true);
  }, [userData?.newPassword, userData?.newPasswordConfirm]);

  const handleTeamClick = async (team, e) => {
    e.preventDefault();

    updateTeamName(team.name);
    updateTeamUUID(team.uuid);
    localStorage.setItem('tmx', team.uuid);

    const currentPathName = window.location.pathname;
    const pathSegments = currentPathName.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];
    const requiresTeamName = currentPathName.includes('/settings');

    if (requiresTeamName) {
      navigate(
        `/settings/teams/${encodeURIComponent(team.name)}/${lastSegment}`
      );
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setUserData({
      ...userData,
      [name]: value,
    });
  };

  return (
    <div>
      {/** 3) Mobile sidebar with transition (Headless UI) * */}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-900 focus:outline-none">
                <SidebarContent
                  dropdownRef={dropdownRef}
                  isDropdownOpen={isDropdownOpen}
                  toggleDropdown={toggleDropdown}
                  handleKeyDown={handleKeyDown}
                  teams={teams}
                  team={team}
                  userData={userData}
                  handleTeamClick={handleTeamClick}
                  setIsDropdownOpen={setIsDropdownOpen}
                  navigation={navigation}
                  teamName={teamName}
                  updateTeamName={updateTeamName}
                  setShouldRestartGuidedTour={setShouldRestartGuidedTour}
                  updateOpenModal={updateOpenModal}
                  openModal={openModal}
                  navigate={navigate}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/** 4) Static sidebar for desktop * */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        <SidebarContent
          dropdownRef={dropdownRef}
          isDropdownOpen={isDropdownOpen}
          toggleDropdown={toggleDropdown}
          handleKeyDown={handleKeyDown}
          team={team}
          teams={teams}
          userData={userData}
          handleTeamClick={handleTeamClick}
          setIsDropdownOpen={setIsDropdownOpen}
          navigation={navigation}
          teamName={teamName}
          updateTeamName={updateTeamName}
          setShouldRestartGuidedTour={setShouldRestartGuidedTour}
          updateOpenModal={updateOpenModal}
          openModal={openModal}
          navigate={navigate}
        />
      </div>

      {/** 5) Top bar for mobile (Hamburger icon) * */}
      <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-gray-800 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-400"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      {/** 6) Main content * */}
      <main className="h-screen py-10 lg:pl-72">
        <div className="h-full px-4 sm:px-6 lg:px-8">{children}</div>
      </main>

      {/** 7) Modal for personal settings (unchanged) * */}
      <Modal open={openModal} setOpen={updateOpenModal}>
        <form
          className="space-y-6"
          onSubmit={handleSubmit(fetchPutUserByUUIDData)}
        >
          <AddMemberPopupHeader
            data={{
              uuid: userData?.uuid,
              mail: userData?.mail,
              firstName: userData?.firstName,
              lastName: userData?.lastName,
            }}
            isMailRequired
            register={register}
            errors={errors}
            handleInputChange={handleInputChange}
          />

          <div className="pt-2" />

          <TabsWithUnderline tabs={modalTabs} handleChange={updateModalTabs} />
          {modalTabs.map(
            (tab, index) =>
              tab.current && (
                <div key={index}>
                  {tab.name === 'Reset password' && (
                    <>
                      <LabelInput
                        label="Current password"
                        id="currentPassword"
                        type="password"
                        updateData={(e) =>
                          setUserData({
                            ...userData,
                            currentPassword: e,
                          })
                        }
                        register={register}
                        errors={errors}
                        registerName="currentPassword"
                        registerValue={/^(?=.*[A-Za-z])(?=.*\d).{8,}$/}
                        registerMessage="Invalid password format"
                      />
                      <div className="mt-8" />
                      <LabelInput
                        label="New password"
                        id="newPassword"
                        type="password"
                        updateData={(e) =>
                          setUserData({
                            ...userData,
                            newPassword: e,
                          })
                        }
                        register={register}
                        errors={errors}
                        registerName="newPassword"
                        registerValue={/^(?=.*[A-Za-z])(?=.*\d).{8,}$/}
                        registerMessage="Invalid password format"
                      />
                      <p className="text-xs mt-1 text-gray-500 italic">
                        Must be at least 8 characters long with letters and
                        numbers.
                      </p>
                      <div className="mt-2" />
                      <LabelInput
                        label="Confirm new password"
                        id="confirmNewPassword"
                        type="password"
                        updateData={(e) =>
                          setUserData({
                            ...userData,
                            newPasswordConfirm: e,
                          })
                        }
                      />
                      {!passwordsMatch && (
                        <p className="text-red-500 text-sm mt-2">
                          Passwords do not match. Please try again.
                        </p>
                      )}
                    </>
                  )}
                </div>
              )
          )}

          <ModalHandleButtons
            label="Save"
            labelCancel="Cancel"
            onClickCancel={() => updateOpenModal(false)}
          />
        </form>
      </Modal>
    </div>
  );
};
