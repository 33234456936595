import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { dynamicApiCall } from '../services/api/response/callResponsehandler';
import { useTeam } from './TeamContext';

// Create the context
const UserContext = createContext();

// Custom hook to access the User context
export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null); // To store the user data
  const { teamUUID } = useTeam();

  // Function to fetch user data by Auth token
  const fetchUserData = async (teamUUID) => {
    await dynamicApiCall({
      callName: 'getUsersByAuthToken',
      params: {
        setUserData,
        teamUUID,
      },
    });
  };

  useEffect(() => {
    if (teamUUID) {
      fetchUserData(teamUUID); // Call the function when the component mounts
    }
  }, [teamUUID]);

  // Memoize the context value
  const contextValue = useMemo(
    () => ({
      userData,
      setUserData,
      fetchUserData,
    }),
    [userData]
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};
