export const putUserByUUIDLogic500 = async (datas) => {
  const {
    updateOpenModal,
    callData,
    updateOpenNotification,
    updateNotificationData,
  } = datas;

  if (callData) {
    updateOpenNotification(true);
    updateNotificationData({
      ...callData,
    });
    updateOpenModal(false);
  }
};
