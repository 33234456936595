export const attachClientsToProjects = async (
  relevantProjects,
  teamUUID,
  clientCache,
  navigate,
  dynamicApiCall
) => {
  const updatedProjects = [];

  for (const project of relevantProjects) {
    if (project.clientUUID) {
      if (clientCache.current[project.clientUUID]) {
        project.client = clientCache.current[project.clientUUID];
      } else {
        await dynamicApiCall({
          callName: 'getTeamClients',
          navigate,
          params: {
            teamUUID,
            clientUUID: project.clientUUID,
            setClient: (clientData) => {
              clientCache.current[project.clientUUID] = clientData;
              project.client = clientData;
            },
          },
        });
      }
    }

    // Only push to updatedProjects if project.client is defined
    if (project.client) {
      updatedProjects.push(project);
    }
  }

  return updatedProjects;
};
