export const putTeamBudgetsLogic = async (datas) => {
  const {
    updateOpenSlideOver,
    updateOpenNotification,
    updateNotificationData,
    callData,
    navigate,
    reset,
    resetValues,
    name,
    teamUUID,
    fetchTeamBudgetsByNamePromise,
    fetchTeamProjectsPromise,
  } = datas;
  navigate(`/budgets/${encodeURIComponent(callData.payload)}`);

  if (fetchTeamBudgetsByNamePromise) {
    await fetchTeamBudgetsByNamePromise(name);
  }

  if (fetchTeamProjectsPromise) {
    await fetchTeamProjectsPromise({ teamUUID });
  }

  updateOpenNotification(true);
  updateNotificationData({ ...callData });
  updateOpenSlideOver(false);
  reset({ ...resetValues });
};
