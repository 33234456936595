import { BanknotesIcon } from '@heroicons/react/24/outline';
import { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { startOfToday, endOfYear, format } from 'date-fns';
import { useTeam } from '../../contexts/TeamContext';
import { Searchbar } from '../atoms/Searchbar';
import { PageHeading } from '../molecules/PageHeading';
import { PageHeadingButton } from '../molecules/PageHeadingButton';
import { SideBarLayout } from '../organisms/SiderBarLayout';
import { EmptyStates } from '../organisms/EmptyStates';
import { SlideOver } from '../molecules/SlideOver';
import { SlideOverFormContainer } from '../molecules/SliderOverFormContainer';
import { SlideOverInputContainer } from '../molecules/SlideOverInputContainer';
import { SlideOverInputLabel } from '../molecules/SlideOverInputLabel';
import { SlideOverInputTextArea } from '../molecules/SlideOverInputTextArea';
import { SlideOverInputText } from '../molecules/SlideOverInputText';
import { SlideOverHandleButtons } from '../molecules/SlideOverHandleButtons';
import { CalendarPicker } from '../atoms/CalendarPicker';
import { SlideOverInputCurrencyAmount } from '../molecules/SlideOverInputCurrencyAmount';
import { dynamicApiCall } from '../../services/api/response/callResponsehandler';
import { NotificationContext } from '../../contexts/NotificationContext';
import { TableWithStickyHeader } from '../molecules/TableWithStickyHeader';
import { TableCellText } from '../molecules/TableCellText';
import { TableCellCurrencyAmount } from '../molecules/TableCellCurrencyAmount';
import { TableCellDatesPeriod } from '../molecules/TableCellDatesPeriod';
import { TableCellLink } from '../molecules/TableCellLink';
import { TableCellPulseLoader } from '../molecules/TableCellPulseLoader';
import { TableCellClickableAvatarGroup } from '../molecules/TableCellClickableAvatarGroup';
import {
  tableCellTextSort,
  tableCellAmountSort,
  tableCellDateSort,
  tableCellArrayLengthSort,
} from '../../services/utils/tableFilters';
import { attachAllocationsToProjects } from '../../services/utils/budgets/attachAllocationsToProjects';
import { attachClientsToProjects } from '../../services/utils/budgets/attachClientsToProjects';
import { calculateTotalAllocationsAmountForMembers } from '../../services/utils/budgets/calculateTotalAllocationsAmountForMembers';
import TableCellTextWithBarChart from '../molecules/TableCellTextWithBarChart';
import { SlideOverInputItemsToggle } from '../molecules/SlideOverInputItemsToggle';

export default function BudgetsScreen() {
  const navigate = useNavigate();
  const teamData = useTeam();
  const { updateOpenNotification, updateNotificationData } =
    useContext(NotificationContext);

  const [budgets, setBudgets] = useState([]);
  const [projects, setProjects] = useState([]);
  const [isFetchingProjects, setIsFetchingProjects] = useState(true);
  const [openBudgetSlideOver, setOpenBudgetSlideOver] = useState(false);
  const [userPrivileges, setUserPrivileges] = useState('member');
  const [searchedBudgetsResult, setSearchedBudgetsResult] = useState([]);
  const [membersAllocations, setMembersAllocations] = useState({}); // To store the calculated allocation amount for members
  const [loadingBudgets, setLoadingBudgets] = useState(new Set());

  const memberCache = useRef({});
  const clientCache = useRef({});

  const updateOpenBudgetSlideOver = (newOpenSlideOver) => {
    setOpenBudgetSlideOver(newOpenSlideOver);
  };

  const updateSearchedBudget = (searchBudgetName) => {
    const searchLower = searchBudgetName.toLowerCase();

    const filteredBudgets = budgets
      .map((budget) => {
        if (budget.name.toLowerCase().includes(searchLower)) {
          return {
            ...budget,
          };
        }
        return null;
      })
      .filter((client) => client !== null); // Filter out null entries

    setSearchedBudgetsResult(filteredBudgets);
  };

  /* BEGINNING CALL API */
  const fetchTeamBudgetsByTeamUUIDPromise = async (teamUUID) => {
    await dynamicApiCall({
      callName: 'getTeamBudgetsByTeamUUID',
      navigate,
      params: { teamUUID, setBudgets, setSearchedBudgetsResult },
    });
  };

  /* Get team project user can allocate also used for filters */
  const fetchTeamProjectsPromise = async ({ teamUUID, userUUID }) => {
    setIsFetchingProjects(true);
    await dynamicApiCall({
      callName: 'getTeamProjectsByTeamUUID',
      navigate,
      params: {
        teamUUID,
        userUUID,
        setProjects: async (fetchedProjects) => {
          const updatedProjectsWithClients = await attachClientsToProjects(
            fetchedProjects,
            teamUUID,
            clientCache,
            navigate,
            dynamicApiCall
          );
          setProjects(updatedProjectsWithClients);
        },
      },
    });
    setIsFetchingProjects(false);
  };

  const postTeamBudgetsCall = async (inputData, reset) => {
    const params = {
      teamUUID: teamData.teamUUID,
      name: inputData.budgetName || undefined,
      description: inputData.budgetDescription || undefined,
      notes: inputData.budgetNotes || undefined,
      startedAt: inputData.from || undefined,
      endedAt: inputData.to || undefined,
      amount: inputData.budgetAmount || undefined,
      projectsUUIDs:
        Object.entries(inputData.budgetProjects)
          .filter(([key, value]) => value === true)
          .map(([key, value]) => key) || undefined, // Get the keys === true as an array [project1, project2, ...]
    };

    await dynamicApiCall({
      callName: 'postTeamBudgets',
      navigate,
      params: {
        ...params,
        fetchTeamBudgetsByTeamUUIDPromise,
        fetchTeamProjectsPromise,
        reset,
        updateOpenSlideOver: updateOpenBudgetSlideOver,
        updateOpenNotification,
        updateNotificationData,
      },
    });
  };

  /* Check if user can access this page */
  const fetchAccessPagePromise = async (teamUUID, page) => {
    await dynamicApiCall({
      callName: 'getAccessPage',
      navigate,
      params: {
        teamUUID,
        navigate,
        page,
        setUserPrivileges,
        updateNotificationData,
        updateOpenNotification,
      },
    });
  };

  useEffect(() => {
    if (teamData.teamUUID) {
      fetchAccessPagePromise(teamData.teamUUID, 'Budgets');
      fetchTeamBudgetsByTeamUUIDPromise(teamData.teamUUID);
      fetchTeamProjectsPromise({ teamUUID: teamData.teamUUID });
    }
  }, [teamData.teamUUID, navigate]);

  // Helper function to parse budgets JSON string and check if it contains the budget UUID
  const getProjectsForBudget = (budgetUUID) =>
    projects.filter((project) => {
      // Parse the budgets JSON string
      const parsedBudgets = JSON.parse(`[${project.budgets}]`);

      // Check if any of the parsed budgets have the matching UUID
      return parsedBudgets.some(
        (budget) =>
          budget.uuid === budgetUUID &&
          budget.budgetLinkAssociationDeletedAt === null
      );
    });

  const calculateMembersAllocationsForBudgets = async () => {
    const allocations = {};
    const loadingBudgetsSet = new Set(budgets.map((budget) => budget.uuid)); // Initially set all budgets as loading

    setLoadingBudgets(loadingBudgetsSet);

    for (const budget of budgets) {
      const relevantProjects = getProjectsForBudget(budget.uuid);

      if (relevantProjects.length > 0) {
        const projectsWithAllocations = await attachAllocationsToProjects(
          relevantProjects,
          budget,
          teamData.teamUUID,
          memberCache,
          navigate,
          dynamicApiCall
        );

        const totalAmountForMembers = calculateTotalAllocationsAmountForMembers(
          projectsWithAllocations,
          budget,
          teamData
        );

        allocations[budget.uuid] = totalAmountForMembers;
      }

      loadingBudgetsSet.delete(budget.uuid); // Remove budget from loading set when done
      setLoadingBudgets(new Set(loadingBudgetsSet)); // Trigger re-render
    }

    setMembersAllocations(allocations);
  };

  useEffect(() => {
    if (budgets.length > 0 && projects.length > 0) {
      calculateMembersAllocationsForBudgets();
    }
  }, [budgets, projects]);

  return (
    <SideBarLayout>
      <div className="px-4 h-full sm:px-6 lg:px-8 flex flex-col">
        <PageHeading
          pages={[
            {
              name: `Budgets (${searchedBudgetsResult.length})`,
              href: null,
              current: true,
            },
          ]}
        >
          <Searchbar
            placeholder="Search budgets"
            onChange={updateSearchedBudget}
          />
          {userPrivileges !== 'member' ? (
            <PageHeadingButton
              label="Create budget"
              onClick={() => setOpenBudgetSlideOver(true)}
            >
              <BanknotesIcon
                className="-ml-0.5 mr-1.5 h-5 w-5"
                aria-hidden="true"
              />
            </PageHeadingButton>
          ) : null}
        </PageHeading>

        {searchedBudgetsResult.length > 0 ? (
          <TableWithStickyHeader
            tableHeads={{
              name: {
                label: 'Name',
                filteredBy: tableCellTextSort,
                keyToFilterFrom: 'name',
              },
              projects: {
                label: 'Projects attached',
                filteredBy: tableCellArrayLengthSort,
                keyToFilterFrom: (budget) => getProjectsForBudget(budget.uuid),
              },
              spent: {
                label: 'Spent',
                filteredBy: tableCellAmountSort, // Apply amount sort here
                keyToFilterFrom: (budget) =>
                  membersAllocations[budget.uuid]
                    ?.reduce((sum, member) => sum + member.amount, 0)
                    .toFixed(2) || '0.00', // Use the same calculation for sorting
              },
              ratio: {
                label: 'Ratio',
                filteredBy: tableCellAmountSort, // Use the same amount sort for the ratio
                keyToFilterFrom: (budget) =>
                  budget.amount > 0
                    ? (
                        ((membersAllocations[budget.uuid]?.reduce(
                          (sum, member) => sum + member.amount,
                          0
                        ) || 0) /
                          budget.amount) *
                        100
                      ).toFixed(2)
                    : '0',
              },
              amount: {
                label: 'Budget amount',
                filteredBy: tableCellAmountSort,
                keyToFilterFrom: 'amount',
              },
              validity: {
                label: 'Validity period',
                filteredBy: tableCellDateSort,
                keyToFilterFrom: 'startedAt',
              },
            }}
            tableData={searchedBudgetsResult}
          >
            {(sortedData) => (
              <>
                {sortedData.map((budget, index) => (
                  <tr
                    key={index}
                    className="hover:bg-slate-100 cursor-pointer"
                    onClick={() => {
                      navigate(`/budgets/${encodeURIComponent(budget.name)}`);
                    }}
                  >
                    <TableCellText
                      data={budget}
                      label={budget.name}
                      index={index}
                      customClassName="font-normal"
                    />

                    {isFetchingProjects ? (
                      <TableCellPulseLoader data={budget} index={index} />
                    ) : getProjectsForBudget(budget.uuid).length === 0 ? (
                      <TableCellLink
                        data={budget}
                        label="No project"
                        index={index}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/portfolio`);
                        }}
                      />
                    ) : (
                      <TableCellClickableAvatarGroup
                        data={budget}
                        componentData={getProjectsForBudget(budget.uuid)}
                        index={index}
                        onClick={(e, project) =>
                          navigate(
                            `/portfolio/${encodeURIComponent(
                              project.client.name
                            )}/${encodeURIComponent(project.name)}`
                          )
                        } // Pass the handler from the screen
                      />
                    )}

                    {loadingBudgets.has(budget.uuid) ? (
                      <TableCellPulseLoader data={budget} index={index} />
                    ) : (
                      <TableCellCurrencyAmount
                        data={budget}
                        label={
                          membersAllocations[budget.uuid]
                            ?.reduce((sum, member) => sum + member.amount, 0)
                            .toFixed(2) || '0.00'
                        }
                        currencySymbol={teamData.teamCurrencySymbol}
                        index={index}
                      />
                    )}

                    {loadingBudgets.has(budget.uuid) ? (
                      <TableCellPulseLoader data={budget} index={index} />
                    ) : (
                      <TableCellTextWithBarChart
                        data={{
                          consumed:
                            membersAllocations[budget.uuid]?.reduce(
                              (sum, member) => sum + member.amount,
                              0
                            ) || 0,
                          amount: budget.amount,
                        }}
                        label={`${
                          budget.amount > 0
                            ? (
                                ((membersAllocations[budget.uuid]?.reduce(
                                  (sum, member) => sum + member.amount,
                                  0
                                ) || 0) /
                                  budget.amount) *
                                100
                              ).toFixed(2) === '0.00'
                              ? '0'
                              : (
                                  ((membersAllocations[budget.uuid]?.reduce(
                                    (sum, member) => sum + member.amount,
                                    0
                                  ) || 0) /
                                    budget.amount) *
                                  100
                                ).toFixed(2)
                            : '0'
                        }%`}
                        index={index}
                        customClassName="font-normal"
                      />
                    )}

                    <TableCellCurrencyAmount
                      data={budget}
                      label={budget.amount}
                      currencySymbol={teamData.teamCurrencySymbol}
                      index={index}
                    />

                    <TableCellDatesPeriod
                      data={budget}
                      from={budget.startedAt}
                      to={budget.endedAt}
                      index={index}
                    />
                  </tr>
                ))}
              </>
            )}
          </TableWithStickyHeader>
        ) : (
          <div className="h-full flex justify-center items-center">
            <EmptyStates
              displayFor={userPrivileges}
              label="There is no budget yet !"
              subLabel="Start by creating a budget."
              buttonLabel="Create budget"
              onClick={() => {
                updateOpenBudgetSlideOver(!openBudgetSlideOver);
              }}
            >
              <BanknotesIcon
                className="mx-auto h-12 w-12 text-gray-400"
                aria-hidden="true"
              />
            </EmptyStates>
          </div>
        )}
      </div>

      <SlideOver
        open={openBudgetSlideOver}
        setOpen={updateOpenBudgetSlideOver}
        title="Create budget"
        defaultValues={{
          budgetName: null,
          budgetDescription: null,
          budgetNotes: null,
          from: format(startOfToday(), 'yyyy-MM-dd'),
          to: format(endOfYear(new Date()), 'yyyy-MM-dd'),
          budgetAmount: null,
          budgetProjects: projects.reduce((acc, project) => {
            acc[project.uuid] = false; // Initialize all projects as not included by default
            return acc;
          }, {}),
        }}
      >
        <SlideOverFormContainer onSubmit={postTeamBudgetsCall}>
          <SlideOverInputContainer>
            <SlideOverInputLabel label="Budget name" isRequired />
            <SlideOverInputText name="budgetName" isRequired />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel label="Validity period" isRequired />
            <CalendarPicker isRequired name1="from" name2="to" />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel label="Amount" isRequired />
            <SlideOverInputCurrencyAmount
              name="budgetAmount"
              currencySymbol={teamData.teamCurrencySymbol}
              currencyName={teamData.teamCurrencyName}
              isRequired
            />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel
              label="Associated projects"
              subLabel="Amount will be equally splitted between each selected projects."
              linkData={{ to: `/portfolio`, label: `Manage projects` }}
            />
            <SlideOverInputItemsToggle
              name="budgetProjects"
              data={projects.map((project) => ({
                ...project,
                dataType: 'projects',
              }))}
            />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel label="Description" />
            <SlideOverInputTextArea name="budgetDescription" rows={1} />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel label="Notes" />
            <SlideOverInputTextArea name="budgetNotes" rows={2} />
          </SlideOverInputContainer>

          <SlideOverHandleButtons submitLabel="Create budget" />
        </SlideOverFormContainer>
      </SlideOver>
    </SideBarLayout>
  );
}
