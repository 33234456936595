import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { SideBarLayout } from '../organisms/SideBarLayout';
import { SubSideBarLayout } from '../organisms/SubSideBarLayout';
import { SingleScreenTitle } from '../atoms/SingleScreenTitle';
import { SubTitle } from '../atoms/SubTitle';
import { ActionPanel } from '../molecules/ActionPanel';
import { Table } from '../molecules/Table';
import { ColorPicker } from '../atoms/ColorPicker';
import { Modal } from '../molecules/Modal';
import { useTeam } from '../../contexts/TeamContext';
import { ModalHandleButtons } from '../molecules/ModalHandleButtons';
import { dynamicApiCall } from '../../services/api/response/callResponsehandler';
import { NotificationContext } from '../../contexts/NotificationContext';
import { DeleteModalInput } from '../molecules/DeleteModalInput';

export const SettingsTagsScreen = () => {
  /* Access context values using the useTeam hook */
  const { teamUUID } = useTeam();

  /* Access context notifs */
  const { updateOpenNotification, updateNotificationData } =
    useContext(NotificationContext);

  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [tags, setTags] = useState([]);
  const [createTag, setCreateTag] = useState({
    name: '',
    color: null,
    description: null,
  });
  const [modalSource, setModalSource] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);

  const updateOpenModal = (newOpenModal) => {
    setOpenModal(newOpenModal);
  };

  const updateModalSource = (newModalSource) => {
    setModalSource(newModalSource);
  };

  const updateSelectedTag = (newValue, key = null) => {
    if (key) {
      // Update a specific property of the selectedTag
      setSelectedTag((prevTag) => ({
        ...prevTag,
        [key]: newValue,
      }));
    } else {
      // Update the entire selectedTag object
      setSelectedTag(newValue);
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  // Watch for changes
  const tagName = watch('tagName', selectedTag?.names);

  useEffect(() => {
    // Set the default value when selectedTag changes
    setValue('tagName', selectedTag?.names, { shouldDirty: true });
  }, [selectedTag, setValue]);

  /* BEGINNING CALL API */
  const fetchTeamTagsByTeamUUIDPromise = async ({
    teamUUID,
    fetchNumberTagUsedInProjects,
  }) => {
    await dynamicApiCall({
      callName: 'getTeamTagsByTeamUUID',
      navigate,
      params: {
        teamUUID,
        fetchNumberTagUsedInProjects,
        setListOfTags: setTags,
      },
    });
  };

  const fetchPostTeamTagsPromise = async ({ teamUUID }) => {
    const params = {
      name: createTag.name,
      description: createTag.description ? createTag.description : undefined,
      teamUUID,
      color: createTag.color ? createTag.color : undefined,
      fetchTeamTagsByTeamUUIDPromise,
    };

    await dynamicApiCall({
      callName: 'postTeamTags',
      navigate,
      params: {
        ...params,
        updateOpenNotification,
        updateNotificationData,
      },
    });
  };

  /* Check if user can access this page */
  const fetchAccessPagePromise = async (teamUUID, page, optionalText) => {
    await dynamicApiCall({
      callName: 'getAccessPage',
      navigate,
      params: {
        teamUUID,
        navigate,
        page,
        optionalText,
        updateNotificationData,
        updateOpenNotification,
      },
    });
  };

  const putTagCall = async () => {
    const params = {
      teamUUID,
      uuid: selectedTag.uuid,
      name: selectedTag.names,
      color: selectedTag.colors,
    };

    await dynamicApiCall({
      callName: 'putTeamTagsByUUID',
      navigate,
      params: {
        ...params,
        updateNotificationData,
        updateOpenNotification,
        updateOpenModal,
        fetchTeamTagsByTeamUUIDPromise,
      },
    });
  };

  const deleteTagCall = async () => {
    await dynamicApiCall({
      callName: 'deleteTeamTagsByUUID',
      navigate,
      params: {
        teamUUID,
        uuids: selectedTag.uuid,
        updateOpenModal,
        fetchTeamTagsByTeamUUIDPromise,
        updateOpenNotification,
        updateNotificationData,
      },
    });
  };
  /* END CALL API */

  useEffect(() => {
    if (teamUUID) {
      fetchAccessPagePromise(teamUUID, 'TeamsSettingsTags', 'settings');
      fetchTeamTagsByTeamUUIDPromise({
        teamUUID,
        fetchNumberTagUsedInProjects: true,
      });
    }
  }, [teamUUID]);

  return (
    <SideBarLayout>
      <SubSideBarLayout>
        <main className="lg:pl-72 xl:pl-96">
          <SingleScreenTitle text="Tags" />
          <SubTitle text="Manage the Tags you apply to Projects and Allocations." />
          <div className="mt-4" />

          <ActionPanel title="Create tag">
            <input
              type="name"
              name="name"
              id="name"
              onChange={(e) =>
                setCreateTag({ ...createTag, name: e.target.value })
              }
              className="block w-full focus:outline-none rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Tag name"
            />
            <ColorPicker
              setState={setCreateTag}
              label={null}
              placeholder="Select the tag color"
            />
            <div className="flex justify-end">
              <button
                type="button"
                onClick={() => fetchPostTeamTagsPromise({ teamUUID })}
                className="inline-flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:w-auto"
              >
                Create
              </button>
            </div>

            {tags ? (
              <Table
                tableLabel="Tags list"
                tableHeads={{
                  names: 'Names',
                  projectsUsing: 'Projects using',
                  colors: 'Colors',
                  actionLabel: 'Actions buttons',
                }}
                tableData={tags.map((tag) => ({
                  ...tag,
                  actionCalls: [
                    {
                      name: (
                        <PencilSquareIcon
                          className="h-5 w-5 mx-2 flex-shrink-0 text-indigo-500"
                          aria-hidden="true"
                        />
                      ),
                      onClick: () => {
                        updateModalSource(`edit`);
                        updateOpenModal(true);
                        updateSelectedTag(tag);
                      },
                    },
                    {
                      name: (
                        <TrashIcon
                          className="h-5 w-5 mx-2 flex-shrink-0 text-red-500"
                          aria-hidden="true"
                        />
                      ),
                      onClick: (tag) => {
                        updateModalSource(`delete`);
                        updateOpenModal(true);
                        updateSelectedTag(tag);
                      },
                    },
                  ],
                }))}
              />
            ) : (
              <div className="text-center">
                <h3 className="mt-2 text-sm font-semibold text-gray-900">
                  No tag
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Create tag to visualize it here !
                </p>
              </div>
            )}
          </ActionPanel>
        </main>

        <Modal open={openModal} setOpen={updateOpenModal}>
          {modalSource === `edit` ? (
            <form onSubmit={handleSubmit(putTagCall)}>
              <p>Edit tag</p>

              <div className="mt-2" />
              <input
                type="name"
                name="tagName"
                id="tagName"
                {...register('tagName', {
                  required: 'A tag name is required',
                })}
                onChange={(e) => {
                  setValue('tagName', e.target.value, {
                    shouldValidate: true,
                  });
                  updateSelectedTag(e.target.value, 'names');
                }}
                className={`block w-full focus:outline-none rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                  errors.tagName ? 'ring-red-500' : ''
                }`}
                placeholder={selectedTag?.names}
                value={tagName}
              />
              {errors && errors.tagName && (
                <span className="text-red-500 text-xs">
                  {errors.tagName.message}
                </span>
              )}

              <ColorPicker
                label={null}
                preselectedColor={selectedTag?.colors}
                placeholder="Select the car color"
                onChangeColor={(colorSelected) =>
                  updateSelectedTag(colorSelected, 'colors')
                }
              />

              <ModalHandleButtons
                label="Save"
                labelCancel="Cancel"
                onClickCancel={() => updateOpenModal(false)}
              />
            </form>
          ) : (
            <DeleteModalInput
              labelToCopy={selectedTag?.names}
              onClickCancel={() => updateOpenModal(false)}
              deleteFunction={deleteTagCall}
            />
          )}
        </Modal>
      </SubSideBarLayout>
    </SideBarLayout>
  );
};
