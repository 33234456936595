export const AvatarWithPlaceholder = ({
  placeholder,
  size = 'xs',
  rounded = 'full',
  className = 'bg-indigo-500',
  onClick = null,
}) => {
  const heightAndWidth = {
    xs: 'h-6 w-6',
    sm: 'h-8 w-8 px-2',
    md: 'h-10 w-10',
    lg: 'h-12 w-12',
    xl: 'h-14 w-14',
    '2xl': 'h-16 w-16',
    '3xl': 'h-20 w-20',
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      onClick && onClick(e);
    }
  };

  return (
    <span
      role="button"
      tabIndex="0"
      className={`${className} inline-flex ${heightAndWidth[size]} mx-2 items-center justify-center rounded-${rounded} cursor-pointer`}
      onClick={onClick}
      onKeyDown={handleKeyDown}
    >
      <span
        className={`flex items-center justify-center ${heightAndWidth[size]} font-medium leading-none text-white text-${size} rounded-${rounded}`}
      >
        {placeholder.toUpperCase()}
      </span>
    </span>
  );
};
