export const putUserByUUIDLogic = async (datas) => {
  const {
    fetchInfo,

    updateOpenModal,
    callData,
    updateOpenNotification,
    updateNotificationData,
  } = datas;

  if (callData) {
    if (fetchInfo) {
      await fetchInfo();
    }
    updateOpenModal(false);
    if (updateOpenNotification && updateNotificationData) {
      updateOpenNotification(true);
      updateNotificationData({ ...callData });
    }
  }
};
