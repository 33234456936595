import React from 'react';

const DropdownButtonsMenu = ({ items }) => (
  <div className="absolute left-full top-0 ml-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 opacity-0 group-hover:opacity-100 scale-95 group-hover:scale-100 transition ease-out duration-200 z-20">
    <ul className="py-1 text-sm text-gray-700">
      {items.map((item, index) => (
        <li key={index}>
          <button
            type="button"
            onClick={item.onClick}
            className="w-full text-left px-4 py-2 hover:bg-gray-100"
          >
            {item.name}
          </button>
        </li>
      ))}
    </ul>
  </div>
);

export default DropdownButtonsMenu;
