import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';

export const productTour = driver({
  showProgress: true,
  steps: [
    {
      element: '#menuYourTeams',
      popover: {
        title: `Welcome !`,
        description: `This section allows you to select teams, create new one and manage settings (if you're owner).`,
        position: 'right',
      },
    },
    {
      element: '#menuPortfolio',
      popover: {
        title: `Your portfolio !`,
        description: `Click on your clients & projects to see details of time spent on it (aka. "Allocations").`,
        position: 'right',
      },
      onHighlighted: () => {
        document.querySelector('#menuLinkPortfolio').click();
      },
    },
    {
      element: '#menuAllocations',
      popover: {
        title: `Most important part !`,
        description: `Allocations menu is basically the reality of the times everyone spent on a project ! There is 3 submenus`,
        position: 'right',
      },
      onHighlighted: () => {
        document.querySelector('#menuLinkAllocations').click();
      },
    },
    {
      element: '#allocationsTabCalendar',
      popover: {
        title: `Calendar`,
        description: `Just click on a cell to allocate your time. 100% should be logged for each worked day.`,
        position: 'right',
      },
      onHighlighted: () => {
        document.querySelector('#allocationsTabCalendar').click();
      },
    },
    {
      element: '#allocationsTabAnomalies',
      popover: {
        title: `Anomalies`,
        description: `No worries, we'll bring you anomalies that you can fix if you forgot one or if it's uncomplete !`,
        position: 'right',
      },
      onHighlighted: () => {
        document.querySelector('#allocationsTabAnomalies').click();
      },
    },
    {
      element: '#allocationsTabHistory',
      popover: {
        title: `History`,
        description: `If you need, the history of your loogged time !`,
        position: 'right',
      },
      onHighlighted: () => {
        document.querySelector('#allocationsTabHistory').click();
      },
    },
  ],
});
